<div class="row" >
  <div class="col-md-4 login-left text-center p-5 primary-background" style="background-color:#009B4C;margin-right: -30px;color: #ffffff;min-height:100vh;">
      
  <p style="font-size: 30px;font-weight: bold;text-align: center;"><img
    src={{applogo}}
    alt="DOWC"
    class="dark-logo"
    style="width: 140px;"
  /> Claims</p>
      <p style="font-size: 16px;text-align: center;">To start a GAP, Lease Wear & Tear, or Key Protection claim, please create your account by clicking on the link below. If you already have an account, please login to pick up where you left off.</p>
      <!-- <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please fill out the form below and one of our Customer
        Service Representatives will be in touch with you shortly. Thank you!
      </p> -->
      <!-- <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please click <a class="btn-link" style="color: #fff;" href="https://dowc.com/contract-holders/#claim">here</a>.<br/> Thank you!
      </p> -->
      <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please click <a class="btn-link" style="color: #fff;" href="https://assuredprovider.com/file-a-claim/">here</a>.<br/> Thank you!
      </p>
    </div>  
  <div class="col-md-8 login-right text-center p-5" >
    <div class="p-3 title-case" style="font-weight:bold; text-transform:uppercase;font-size: 24px;">
      <img class="dark-logo" style="width: 150px;" src={{logo}} alt="DOWC"> <span class="mt-1">
      </span>
    </div>
  <div class="card-body form-items" *ngIf="findForm && !claims">
    <form
      [formGroup]="findForm"
      (ngSubmit)="find()"
      class="form-horizontal form-material"
      id="findForm"
      novalidate
    >
      <h3 class="box-title m-b-5 font-weight-bold">
        Find claim
      </h3>
      <!-- <small class="hidden">Create your account with Claims</small> -->
      <div class="row">
        <div class="col-12 p-xl-0">
          <div class="form-group row ml-0 mr-0">
            <div
              class="form-group m-xl-0 col-12 col-xl-12 d-xl-flex align-items-center p-0"
            >
              <div class="col-12 col-xl-12">
                <app-form-error-wrapper
                  [control]="findForm.controls['contractNumber']"
                  [controlName]="'contractNumber'"
                  [apiErrorType]="'contractNumber'"
                >
                  <input
                    class="form-control"
                    type="text"
                    formControlName="contractNumber"
                    placeholder="Contract Number"
                  />
                </app-form-error-wrapper>
              </div>
            </div>
          </div>      
          <div class="form-group d-xl-flex align-items-center">
            <div class="form-group m-xl-0 col-12 col-xl-12 align-items-center">
              <app-form-error-wrapper
                [control]="findForm.controls['vinNumber']"
                [controlName]="'vin Number'"
                [apiErrorType]="'vinNumber'"
              >
                <input
                  class="form-control"
                  type="text"
                  formControlName="vinNumber"
                  placeholder="VIN Number"
                />
              </app-form-error-wrapper>
              <!-- <input type="text" name="streetAddress" class="form-control" /> -->
            </div>
          </div>
          <div class="form-group d-xl-flex align-items-center">
            <div class="form-group m-xl-0 col-12 col-xl-12 align-items-center p-0">
              <div class="col-12 col-xl-12">
                <app-form-error-wrapper
                  [control]="findForm.controls['sequence']"
                  [controlName]="'sequence'"
                  [apiErrorType]="'sequence'"
                >
                  <input
                    class="form-control"
                    type="text"
                    formControlName="sequence"
                    placeholder="Claim No"
                  />
                </app-form-error-wrapper>
              </div>
              </div>
            </div> 
        </div>
      </div>

      <div class="form-group m-t-2">
        <div class="col-xs-3">
          <button
            type="submit"
            [disabled]="findForm.invalid || isLoading"
            class="btn btn-info waves-effect waves-light"
            >
            <app-button-loader
              [isLoading]="isLoading"
              [label]="'Find'"
            ></app-button-loader>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="card-body form-items" *ngIf="claims">
    <div class="card-body pr-0 pl-0">
      <div class="table-responsive">
        <table class="table mb-table">
          <thead>
            <tr>
              <th colspan="2">Claim Details</th>
            </tr>
          </thead>
          <tbody>              
            <tr>
              <td width="60%">
                <span>Claim No</span>
              </td>
              <td width="40%">
                <a href="{{serverUrl}}/claim/preview/{{claims._id}}" target="_blank">{{ claims.sequence }}</a>
              </td>
            </tr>
            <tr>
              <td>Contract Number</td>
              <td>{{ claims.contractNumber }}</td>
            </tr>
            <tr>
              <td>VIN Number</td>
              <td>{{ claims.vinNumber }}</td>
            </tr>
            <tr>
              <td>
                <span>Claim Status</span>
              </td>
              <td>
                <span class="label text-white"
                [ngClass]="{
                  'bg-inprogress': claims.status === 'in progress',
                  'bg-inreview': claims.status === 'in review',
                  'bg-approved': claims.status === 'approved',
                  'bg-completed': claims.status === 'completed',
                  'bg-rejected': claims.status === 'rejected'
                }">{{ claims.status | titlecase }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Steps Completed</span>
              </td>
              <td>
                {{ claims.currentStep > 4
                ? 4
                : claims.currentStep
                }}
                out of 4
              </td>
            </tr>
          </tbody>
        </table> 
               
        <button class="btn btn-secondary pt-2 mt-2" routerLink="/find">
          Find New
        </button>
      </div>
    </div>
  </div>
</div>
<div class="col-md-6 login-bottom text-center p-5 primary-background" style="background-color:#009B4C;margin-right: -30px;color: #ffffff;">
  <p style="font-size: 30px;font-weight: bold;text-align: center;">{{appname}} Claims</p>
      <p style="font-size: 16px;text-align: center;">To start a GAP, Lease Wear & Tear, or Key Protection claim, please create your account by clicking on the link below. If you already have an account, please login to pick up where you left off.</p>
      <!-- <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please fill out the form below and one of our Customer
        Service Representatives will be in touch with you shortly. Thank you!
      </p> -->
      <!-- <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please click <a class="btn-link" style="color: #fff;" href="https://dowc.com/contract-holders/#claim">here</a>.<br/> Thank you!
      </p> -->
      <p class="mpad-t-5" style="font-size: 16px;text-align: center;">If
        you would like to start a claim for any other product, please click <a class="btn-link" style="color: #fff;" href="https://assuredprovider.com/file-a-claim/">here</a>.<br/> Thank you!
      </p>
    </div>
</div>
