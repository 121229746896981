import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/local-storage.service';
import { LOCAL_VAR } from '@app/core/constants';
var credentialsKey = LOCAL_VAR.credentialsKey;
var domainconfig = LOCAL_VAR.domainconfig;
/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
var AuthenticationService = /** @class */ (function () {
    // private _merchants: Authentication.Merchant | null;
    function AuthenticationService(httpClient, localStorageService) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.merchant$ = new EventEmitter();
        this.credentials$ = new EventEmitter();
        this.domainconfig$ = new EventEmitter();
        this._liveMode = false;
        this.liveMode$ = new EventEmitter();
        var savedCredentials = this.localStorageService.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
        if (this.isAuthenticated()) {
            this.refresh();
        }
    }
    AuthenticationService.prototype.refresh = function () {
        var _this = this;
        setTimeout(function () {
            _this.me().subscribe(function (user) {
                _this._credentials.user = user;
                _this.setCredentials(_this._credentials);
                _this.setConfig();
            }, function (error) {
                console.log("get merchants error: " + error);
            });
        }, 1);
    };
    // setConfig():Promise<any[]> {
    //   return new Promise((resolve, reject) =>{
    //     this.httpClient.get('/auth/config').subscribe((response: any) => {
    //       console.log(response);
    //       resolve(response);
    //     }, reject);
    //    })
    // }
    AuthenticationService.prototype.setLocalConfig = function (config) {
        this.localStorageService.setItem(domainconfig, JSON.stringify(config));
    };
    AuthenticationService.prototype.clearConfig = function () {
        this.localStorageService.clearItem(domainconfig);
    };
    AuthenticationService.prototype.setConfig = function () {
        var _this = this;
        setTimeout(function () {
            _this.config().subscribe(function (config) {
                //console.log(config);
                if (config && config.records) {
                    _this.setLocalConfig(config.records);
                }
                else {
                    _this.clearConfig();
                }
                return config;
            }, function (error) {
                _this.localStorageService.clearItem(domainconfig);
                console.log("get merchants error: " + error);
            });
        }, 1);
    };
    AuthenticationService.prototype.me = function () {
        return this.httpClient.get('/auth/me').pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.config = function () {
        return this.httpClient.get('/auth/config').pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.getconfig = function (domain) {
        return this.httpClient.get("/auth/config/" + domain).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.login = function (payload) {
        var _this = this;
        return this.httpClient.post('/auth/login', payload).pipe(map(function (body) {
            _this.setCredentials(body);
            return body;
        }));
    };
    AuthenticationService.prototype.otplogin = function (payload) {
        var _this = this;
        return this.httpClient.post('/auth/otplogin', payload).pipe(map(function (body) {
            _this.setCredentials(body);
            return body;
        }));
    };
    AuthenticationService.prototype.setPassword = function (payload) {
        return this.httpClient.post('/auth/activation', payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.forgotPassword = function (payload) {
        return this.httpClient.post('/auth/forgotpassword', payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.resetPassword = function (payload) {
        return this.httpClient.post('/auth/updatepassword', payload).pipe(map(function (body) {
            return body;
        }));
    };
    /* signup(payload: any): Observable<Authentication.Credentials> {
      return this.httpClient.post('/auth/signup', payload).pipe(
        map((body: any) => {
          //this.setCredentials(body);
          return body;
        })
      );
    } */
    AuthenticationService.prototype.signup = function (payload) {
        return this.httpClient.post('/auth/signup', payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.verifyemail = function (payload) {
        return this.httpClient.post("/auth/verifyemail", payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.getotp = function (payload) {
        return this.httpClient.post('/auth/getotp', payload).pipe(map(function (body) {
            return body;
        }));
    };
    /*
    verifyEmail_1(emailHash: string): Observable<Authentication.User> {
      return this.httpClient.get(`/auth/verifyEmail?emailHash=${emailHash}`).pipe(
        map((body: Authentication.User) => {
          return body;
        })
      );
    } */
    /**
     * Logs out the user and clear credentials.
     * @return {Observable<boolean>} True if the user was logged out successfully.
     */
    AuthenticationService.prototype.logout = function (flag) {
        var _this = this;
        if (flag) {
            this.setCredentials();
            return of(true);
        }
        else {
            return this.httpClient
                .post('/logout', {
                sessionId: this.credentials.session
            })
                .pipe(map(function () {
                _this.setCredentials();
                return true;
            }));
        }
    };
    AuthenticationService.prototype.uploadPhoto = function (payload) {
        return this.httpClient.put('/auth/uploadFile', payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.deleteFile = function (payload) {
        return this.httpClient.put('/auth/deleteFile', payload).pipe(map(function (body) {
            return body;
        }));
    };
    AuthenticationService.prototype.deleteOtherFile = function (payload) {
        return this.httpClient.put("/claim/" + payload.claimId + "/updatedocs", payload).pipe(map(function (body) {
            return body;
        }));
    };
    /**
     * Checks is the user is authenticated.
     * @return {boolean} True if the user is authenticated.
     */
    AuthenticationService.prototype.isAuthenticated = function () {
        return !!this.credentials;
    };
    Object.defineProperty(AuthenticationService.prototype, "credentials", {
        /**
         * Gets the user credentials.
         * @return {Credentials} The user credentials or null if the user is not authenticated.
         */
        get: function () {
            return this._credentials;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "accessToken", {
        /**
         * Get the auth token.
         * @return {string} The auth token is null if user is not authenticated.
         */
        get: function () {
            return this.credentials ? this.credentials.accessToken : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "merchant", {
        /**
         * Get the auth token.
         * @return {string} The auth token is null if user is not authenticated.
         */
        get: function () {
            return this._merchant ? this._merchant : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "sessionId", {
        /**
         * Get the auth token.
         * @return {string} The auth token is null if user is not authenticated.
         */
        get: function () {
            return this.credentials ? this.credentials.session : null;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sets the user credentials.
     * @param {Credentials=} Authentication.Credentials The user credentials.
     */
    AuthenticationService.prototype.setCredentials = function (credentials) {
        this._credentials = credentials || null;
        if (credentials) {
            this.localStorageService.setItem(credentialsKey, JSON.stringify(credentials));
            this.credentials$.emit(this._credentials);
            if (this.credentials.user.userRoleId.slug === 'customer') {
                this.localStorageService.setItem(LOCAL_VAR.selectedCustomer, JSON.stringify(this.credentials.user));
            }
        }
        else {
            this.localStorageService.clearItem(credentialsKey);
        }
    };
    Object.defineProperty(AuthenticationService.prototype, "liveMode", {
        /**
         * Get Live mode value
         * @return {boolean} True if livemode enabled
         */
        get: function () {
            return this._liveMode;
        },
        /**
         * Set Live mode value
         * @liveMode {boolean}
         */
        set: function (liveMode) {
            this._liveMode = liveMode;
            this.liveMode$.emit(this.liveMode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "loggedInUserType", {
        get: function () {
            if (this.credentials &&
                this.credentials.user &&
                this.credentials.user.userRoleId &&
                this.credentials.user.userRoleId.slug) {
                return this.credentials.user.userRoleId.slug;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    return AuthenticationService;
}());
export { AuthenticationService };
