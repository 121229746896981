<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Support
      </div>
      <div class="card-body p-0">
        <div class="card mb-0">
          <div class="card-body pl-0 pr-0 pb-0">
            <form [formGroup]="fiterForm">
              <a class="cursor-pointer hidden-xl-up pl-2"
                (click)="utilService.toggleDivClass('filter-helps','hidden-lg-down')">
                <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2 d-none"></i>Filter
              </a>
              <div class="row hidden-lg-down" id="filter-helps">
                <div class="col-12 d-xl-flex align-items-start">
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Token</label>
                      <input type="text" name="sequence" class="form-control" formControlName="sequence"
                        (keypress)="omit_special_char($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">From</label>
                      <input class="form-control" type="text" name="dateTimeFromFilter" ngbDatepicker
                        placeholder="MM/DD/YYYY" #date_from="ngbDatepicker" (click)="date_from.toggle()"
                        formControlName="date_from" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(
                            fiterForm,
                            'date_from'
                          )
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['date_from'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">To</label>
                      <input class="form-control" type="text" name="dateTimeToFilter" ngbDatepicker
                        placeholder="MM/DD/YYYY" #date_to="ngbDatepicker" (click)="date_to.toggle()"
                        formControlName="date_to" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(fiterForm, 'date_to')
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['date_to'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Product</label>
                      <select class="form-control custom-select" name="request" formControlName="request">
                        <option value="">All</option>
                        <option value="gap_claim">GAP Claim</option>
                        <option value="Lease_wear_&_tear">Lease Wear & Tear Claim</option>
                        <option value="key_protection">Key Protection Claim</option>
                        <option value="360_shield">360 Shield Claim</option>
                        <option value="debt_cancellation">Debt Cancellation</option>
                        <option value="general">General</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Message</label>
                      <input type="text" name="message" class="form-control" formControlName="message"/>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Status</label>
                      <select class="form-control custom-select" name="status" formControlName="status">
                        <option value="">All</option>
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                      </select>
                    </div>
                  </div>
                  <div class="none">
                    <input type="hidden" name="sortFieldFilter" formControlName="sortFieldFilter" #sortFieldFilter />
                    <input type="hidden" name="sortByFilter" formControlName="sortByFilter" #sortByFilter />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="!isLoading && helpList">
        <div class="table table-responsive smart-table" *ngIf="helpList && helpList.length > 0">
          <table class="w-100">
            <thead>
              <tr>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'sequence',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('sequence')">
                    Ticket
                  </div>
                </th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'updateTime',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('updateTime')">
                    DateTime
                  </div>
                </th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'status',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('request')">
                    Product
                  </div>
                </th>
                <th>Message</th>
                <th>Response</th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'status',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('status')">
                    Status
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let help of helpList
                ">
                <td class="text-capitalize">{{ help.sequence }}</td>
                <td>{{ help.updateTime | date: 'MM/dd/yyyy HH:mm:ss' }}</td>
                <td class="text-capitalize">{{ help.request.split('_').join(' ') }}</td>
                <td><b><span *ngIf="help.cid">{{ help.cid.firstName+' '+ help.cid.lastName }}</span><span
                      *ngIf="!help.cid">{{ help.firstName+' '+ help.lastName }}</span></b><br />
                  {{ (help.message.length>20)? (help.message | slice:0:20)+'...':(help.message) }}</td>
                <td><span *ngIf="help.responseBy"><b>{{ help.responseBy.firstName+' '+ help.responseBy.lastName
                      }}</b><br /></span>
                  <small>{{ help.responseTime | date: 'MM/dd/yyyy HH:mm:ss' }}</small><br />
                  {{ (help.notes.length>20)? (help.notes | slice:0:20)+'...':(help.notes) }}
                </td>
                <td class="text-capitalize text-right">
                  <div class="d-flex align-items-center m-action">
                    <span class="label dark-bg" [ngClass]="{
                    'dark-bg': help.status === 'open',
                    'bg-rejected': help.status === 'closed'
                  }">{{ help.status | titlecase }}</span>
                    <a class="cursor-pointer f-20 l-h-10 ml-5" placement="top" ngbTooltip="Update"
                      (click)="openResponsePopup(help)">
                      <i class="icon-check text-success m-r-10"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-no-data-msg [message]="'No Audits Found'" *ngIf="helpList && helpList.length === 0">
        </app-no-data-msg>

      </div>
      <div class="card-footer" *ngIf="helpList && helpList.length > 0">
        <div class="row">
          <div class="col-md">
            <span>{{
              utilService.showPaginationInfo(pager.Totalrecords, pager.perPage, pager.currentPage)
              }}</span>
          </div>
          <div class="col-sm">
            <form [formGroup]="fiterForm">
              <div class="form-group mb-0 pull-right">
                <small>Rows:</small>
                <select class="form-control custom-select rows-button" name="perPage" formControlName="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input *ngIf="pager.TotalPages && pager.TotalPages>1" type="number" #goto name="goto"
                  class="form-control goto-input" placeholder="Page No" min="1" max="{{pager.TotalPages}}" />
                <span *ngIf="pager.TotalPages && pager.TotalPages>1" class="btn btn-link page-goto goto-btn"
                  (click)="goPage()">
                  Go
                </span>
              </div>
            </form>
          </div>
          <div class="col-md" *ngIf="pager.TotalPages && pager.TotalPages>1">
            <div class="">
              <ul class="pagination pull-right">
                <li>
                  <a (click)="setPage(pager.currentPage - 1)"><i class="icon-arrow-left"></i></a>
                </li>
                <li *ngFor="let page of PageList" [ngClass]="{active:pager.currentPage === page.page}">
                  <a (click)="setPage(page.page)">{{page.label}}</a>
                </li>
                <li>
                  <a (click)="setPage(pager.currentPage + 1)"><i class="icon-arrow-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
    <app-table-loader [isLoading]="isLoading"></app-table-loader>
  </div>
</div>