import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class debitcancelService {
  claimData: any = null;
  claimData$: EventEmitter<any> = new EventEmitter<any>();
  math_min_param_1: any;
  constructor(protected httpClient: HttpClient) {}

 

  add(payload: any) {
      return this.httpClient.post(`/debitcanceladd`, payload).pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  update(claimId:string,payload: any) {
    return this.httpClient.post(`/debitcancelupdate/${claimId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
}

getByFilter(payload: any, customerId?: String) {
  let params = customerId
    ? new HttpParams().set('customerId', `${customerId}`)
    : new HttpParams();
  params = payload.claimDate_from
    ? params.append('claimDate_from', `${payload.claimDate_from}`)
    : params;
  params = payload.claimDate_to
    ? params.append('claimDate_to', `${payload.claimDate_to}`)
    : params;
  params = payload.addendumNumber
    ? params.append('addendumNumber', `${payload.addendumNumber}`)
    : params;
  params = payload.sequence
    ? params.append('sequence', `${payload.sequence}`)
    : params;
  params = payload.status
    ? params.append('status', `${payload.status}`)
    : params;
  params = payload.debitorName
    ? params.append('debitorName', `${payload.debitorName}`)
    : params;
  params = payload.debitorEmail
    ? params.append('debitorEmail', `${payload.debitorEmail}`)
    : params;
  params = payload.dealer
    ? params.append('dealer', `${payload.dealer}`)
    : params;
  params = payload.sortFieldFilter
    ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
    : params;
  params = payload.sortByFilter
    ? params.append('sortByFilter', `${payload.sortByFilter}`)
    : params;
  params = params.append('currentPage', `${payload.currentPage}`);
  params = params.append('perPage', `${payload.perPage}`);

  return this.httpClient.get(`/debitcancelclaims`, { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getById(claimId: string) {
  return this.httpClient.get(`/debitcancel/${claimId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

acceptRequest(claimId: string, payload: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/acceptrequest`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

rejectRequest(claimId: string, payload: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/rejectrequest`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

changeStatus(claimId: string, payload: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/changestatus`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

deleteClaim(claimId: String) {
  return this.httpClient.delete(`/debitcancel/${claimId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAnalytics(payload: any) {
  let params = null;
  if (payload) {
    params = new HttpParams().set('from', `${payload.from}`);
    params = params.append('to', `${payload.to}`);
  }
  if (payload.dealer && payload.dealer !== '') {
    params = params.append('dealer', `${payload.dealer}`);
  }

  return this.httpClient.get(`/debitcancelanalytics`, { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

}
