import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClaimService = /** @class */ (function () {
    function ClaimService(httpClient, httpBackend) {
        this.httpClient = httpClient;
        this.claimData = null;
        this.claimData$ = new EventEmitter();
        this.directcall = new HttpClient(httpBackend);
    }
    ClaimService.prototype.getClaims = function (customerId) {
        var params = null;
        if (customerId) {
            params = new HttpParams().set('customerId', "" + customerId);
        }
        return this.httpClient.get("/claims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getClaimsByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.dateOfLoss_at_from
            ? params.append('dateOfLoss_at_from', "" + payload.dateOfLoss_at_from)
            : params;
        params = payload.dateOfLoss_at_to
            ? params.append('dateOfLoss_at_to', "" + payload.dateOfLoss_at_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        params = params.append('mode', "" + payload.mode);
        return this.httpClient.get("/claims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getClaimReport = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.dateOfLoss_at_from
            ? params.append('dateOfLoss_at_from', "" + payload.dateOfLoss_at_from)
            : params;
        params = payload.dateOfLoss_at_to
            ? params.append('dateOfLoss_at_to', "" + payload.dateOfLoss_at_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        params = params.append('mode', "" + payload.mode);
        return this.httpClient.get("/report", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getReopenedClaims = function (payload) {
        var params = payload.currentPage
            ? new HttpParams().set('currentPage', "" + payload.currentPage)
            : new HttpParams();
        params = params.append('perPage', "" + payload.perPage);
        params = payload.dateOfLoss_at_from
            ? params.set('dateOfLoss_at_from', "" + payload.dateOfLoss_at_from)
            : params;
        params = payload.dateOfLoss_at_to
            ? params.append('dateOfLoss_at_to', "" + payload.dateOfLoss_at_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        return this.httpClient.get("/reopened", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getClaimById = function (claimId) {
        var _this = this;
        this.httpClient.get("/claim/" + claimId).subscribe(function (body) {
            if (body) {
                _this.claimData = body.records;
                _this.claimData$.emit(_this.claimData);
            }
            else {
                _this.claimData = null;
            }
        });
    };
    ClaimService.prototype.getClaimDetails = function (claimId) {
        return this.httpClient.get("/claim/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.stepSubmit = function (stepNo, payload, isAddMode) {
        if (stepNo === 1 && isAddMode) {
            return this.httpClient.post("/claim/step" + stepNo, payload).pipe(map(function (body) {
                return body;
            }));
        }
        else {
            return this.httpClient.put("/claim/step" + stepNo, payload).pipe(map(function (body) {
                return body;
            }));
        }
    };
    ClaimService.prototype.deleteClaim = function (claimId) {
        return this.httpClient.delete("/claim/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.acceptRequest = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.rejectRequest = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/rejectrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.denieRequest = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/denierequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.acceptExceptionRequest = function (claimId, payload) {
        return this.httpClient
            .put("/claim/" + claimId + "/acceptexceptionrequest", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.helprequest = function (helpFormdata) {
        return this.httpClient.put("/claim/helprequest", helpFormdata).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.createSharepointTicket = function (helpFormdata) {
        //console.log(helpFormdata, 'fffffffff');
        var sharepoint_data = {
            username: 'sp@dowc.in',
            password: 'WGF5NjYzMzI=',
            asssigne_email: 'Gunaseelanm@dowc.in',
            ticket_id: helpFormdata.ticketId,
            ticket_title: 'Claims1',
            ticket_category: helpFormdata.request,
            ticket_status: 'Active',
            description: helpFormdata.message,
            Ticket_email: helpFormdata.email,
            firstname: helpFormdata.firstName,
            lastname: helpFormdata.lastName
        };
        return this.directcall
            .post(environment.sharepointAPI, sharepoint_data)
            .pipe(map(function (body) {
            console.log(body);
            return body;
        }));
    };
    ClaimService.prototype.helpUpdate = function (helpId, helpFormdata) {
        return this.httpClient
            .put("/claim/" + helpId + "/helpupdate/", helpFormdata)
            .pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.changeStatus = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.downloadDocuments = function (claimId) {
        // return this.httpClient.get(`/claim/${claimId}/download`, {"responseType": 'arraybuffer'})
        return this.httpClient.get("/claim/" + claimId + "/download").pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.updateGAPCalculation = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/updategap", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.updateDocUpload = function (claimId, payload) {
        return this.httpClient.put("/claim/" + claimId + "/updatedocs", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.GAPCalculation = function (params) {
        var adjusted_amount = 0;
        var C_HiddenValue = params.mSRPNADARetail * (params.maxLimit / 100);
        //new calculation - begin
        if (params.frequencyOfPayments != null) {
            params.numberofPaymentsperYear = params.frequencyOfPayments;
        }
        else {
            params.numberofPaymentsperYear = 0;
        }
        if (params.firstDuedate != null) {
            var date1 = new Date(params.dateOfLoss_at);
            var date2 = new Date(DateTime.fromObject(params.firstDuedate).toISO());
            var Difference_In_Days = Math.round((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
            params.numberofPaymentsElapsed = Math.max(Math.floor((Difference_In_Days / 365) * params.numberofPaymentsperYear) + 1, 0);
        }
        else {
        }
        // if (params.balloonPayment === '' || params.balloonPayment === 0) {
        //   const rate_per_period =
        //     params.financeAPR / 100 / params.numberofPaymentsperYear;
        //   const number_of_payments = params.numberOfPayments;
        //   const present_value = params.actualAmountFinanced;
        //   const future_value = 0;
        //   const type = 0;
        //   params.paymentsPerPeriod = this.pmt(
        //     rate_per_period,
        //     number_of_payments,
        //     present_value,
        //     future_value,
        //     type
        //   );
        //   //  console.log('params.paymentsPerPeriod1',params.paymentsPerPeriod)
        // } else {
        //   const rate_per_period =
        //     params.financeAPR / 100 / params.numberofPaymentsperYear;
        //   const number_of_payments = params.numberOfPayments - 1;
        //   const present_value = params.actualAmountFinanced;
        //   const future_value = params.balloonPayment;
        //   const type = 0;
        //   // console.log('rate_per_period=  ',rate_per_period,'number_of_payments= ',number_of_payments,'present_value = ',present_value,'future_value = ',future_value)
        //   params.paymentsPerPeriod = this.pmt(
        //     rate_per_period,
        //     number_of_payments,
        //     -present_value,
        //     future_value,
        //     type
        //   );
        //   //  console.log('params.paymentsPerPeriod2',params.paymentsPerPeriod)
        // }
        /* params.paymentsPerPeriod = (
          parseFloat(
            (
              this.rate(
                params.numberOfPayments,
                params.financeAPR,
                -params.actualAmountFinanced,
                params.balloonPayment,
                0,
                undefined
              ) * params.numberofPaymentsperYear
            ).toFixed(5)
          ) * 100
        ).toFixed(4); */
        // console.log('params.numberOfPayments == ',params.numberOfPayments,'params.financeAPR == ',params.financeAPR,'-params.actualAmountFinanced ==',-params.actualAmountFinanced,'params.balloonPayment ==',params.balloonPayment,0,10);
        // console.log('uniq ==== ', this.rate(params.numberOfPayments,params.financeAPR,-params.actualAmountFinanced,params.balloonPayment,0,0.1));
        // console.log('rate === ',parseFloat((this.rate(params.numberOfPayments,params.financeAPR,-params.actualAmountFinanced,params.balloonPayment,0,undefined)*params.numberofPaymentsperYear).toFixed(5))*100);
        /* let test=0.0464649;
        console.log('rateeee ===== ',test); */
        // console.log(params.numberOfPayments,
        //   params.financeAPR,
        //   -params.actualAmountFinanced,
        //   params.balloonPayment,
        //   0,
        //   undefined, params.numberofPaymentsperYear
        // );
        // console.log(this.RATE(
        //   params.numberOfPayments,
        //   params.financeAPR,
        //   -params.actualAmountFinanced,
        //   params.balloonPayment,
        //   0,
        //   undefined
        // ));
        // let rate:any=this.RATE(
        //   params.numberOfPayments,
        //   params.financeAPR,
        //   -params.actualAmountFinanced,
        //   params.balloonPayment,
        //   0,
        //   undefined
        // );
        // let rate1:any=rate*params.numberofPaymentsperYear;
        // let percern:any=(rate1 * 100);
        // console.log(rate, rate1, percern);
        params.realizedAPR = (parseFloat((this.RATE(params.numberOfPayments, params.financeAPR, -params.actualAmountFinanced, params.balloonPayment, 0, undefined) * params.numberofPaymentsperYear).toFixed(5)) * 100).toFixed(4);
        var fv_param_1 = params.realizedAPR / 100 / params.numberofPaymentsperYear;
        this.math_min_param_1 = this.FV(fv_param_1, params.numberofPaymentsElapsed, params.financeAPR, -params.actualAmountFinanced, 0);
        params.projectedPrincipalBalance = Math.min(this.math_min_param_1, params.actualAmountFinanced);
        params.estimatedPrincipalPaid =
            params.actualAmountFinanced - params.projectedPrincipalBalance;
        // console.log('fv_param_1 = ',fv_param_1,'params.numberofPaymentsElapsed = ',params.numberofPaymentsElapsed,'params.paymentsPerPeriod = ',params.paymentsPerPeriod,'-params.actualAmountFinanced = ',-params.actualAmountFinanced)
        // console.log('params.projectedPrincipalBalance = ',params.projectedPrincipalBalance)
        // gap - calculation - begin
        params.amountFinancedActual1 = Math.min(params.actualAmountFinanced, params.mSRPNADARetail * 1.5);
        params.amountFinancedTruncated =
            (params.maxLimit / 100) * params.mSRPNADARetail;
        params.ActualvsTruncated = Math.min(params.amountFinancedActual, params.amountFinancedTruncated);
        params.lessprincipalPayment = -params.estimatedPrincipalPaid;
        params.adjustedAmountFinancednew = parseFloat((params.ActualvsTruncated + params.lessprincipalPayment).toFixed(2));
        params.lesserAdjustedAmount = parseFloat(Math.min(params.adjustedAmountFinancednew, params.actualPayoffAmount).toFixed(2));
        params.insurenceSettlementAllRefund = parseFloat((params.insuranceSettlement +
            params.Dedectibles +
            params.gapContractRefund +
            params.otherProductsRefunds).toFixed(2));
        // params.insurenceSettlementAllRefund = Math.min(
        //   params.insuranceSettlementDedectibles +
        //     params.gapContractRefund +
        //     params.otherProductsRefunds,
        //   params.currentPayoff
        // );
        // console.log('params.insuranceSettlementDedectibles = ',params.insuranceSettlementDedectibles,'params.gapContractRefund = ',params.gapContractRefund,'params.otherProductsRefunds = ',params.otherProductsRefunds, 'params.insurenceSettlementAllRefund = ', params.insurenceSettlementAllRefund)
        // console.log('params.conditionAdjustmentA == ',params.conditionAdjustmentA);
        // console.log('params.PriorLoanBalanceRollover == ',params.PriorLoanBalanceRollover);
        params.totalConditionAdjustment = parseFloat((params.conditionAdjustmentA +
            params.conditionAdjustmentB +
            params.conditionAdjustmentC).toFixed(2));
        /* console.log("params.conditionAdjustmentA==",params.conditionAdjustmentA,    "params.conditionAdjustmentB==" ,params.conditionAdjustmentB )
        console.log(params.totalConditionAdjustment); */
        parseFloat(Math.max(params.lesserAdjustedAmount -
            params.insurenceSettlementAllRefund -
            params.totalConditionAdjustment, 0).toFixed(2));
        params.gapsettlement = parseFloat(Math.max(params.lesserAdjustedAmount -
            params.insurenceSettlementAllRefund -
            params.totalConditionAdjustment, 0).toFixed(2));
        // console.log('params.amountFinancedActual+params ===',params.amountFinancedActual,'params.lessprincipalPayment ===',params.lessprincipalPayment)
        // console.log(params.amountFinancedActual+params.lessprincipalPayment)
        params.AmortizedPrincipalBalance = parseFloat((params.amountFinancedActual1 + params.lessprincipalPayment).toFixed(2));
        params.lesserPayofforAmortized = Math.min(params.AmortizedPrincipalBalance, params.actualPayoffAmount);
        // console.log('params.gapsettlement == ',params.gapsettlement)
        // console.log('params.gapCalculationApproval === ',params.claimcalculationstatus)
        if (params.gapCalculationApproval === 'approved') {
            params.excessGapSettlement = Math.max(params.gapsettlement, 0);
        }
        else {
            params.excessGapSettlement = 0;
        }
        // console.log('params.insuranceSettlementDedectibles==',params.insuranceSettlementDedectibles,'params.gapContractRefund==',params.gapContractRefund,'+params.otherProductsRefunds==',params.otherProductsRefunds,'+params.totalConditionAdjustment',params.totalConditionAdjustment,'+params.excessGapSettlement==',params.excessGapSettlement)
        params.lessAllCredites = parseFloat((params.insuranceSettlement +
            params.gapContractRefund +
            params.otherProductsRefunds +
            params.totalConditionAdjustment +
            params.Dedectibles +
            params.excessGapSettlement).toFixed(2));
        params.ExcessGapAmount = parseFloat(Math.max(params.lesserPayofforAmortized - params.lessAllCredites, 0).toFixed(2));
        params.TotalAdjustmentcancellation =
            params.totalConditionAdjustment + params.cancellationFee;
        params.GapAmountAftercancel = parseFloat(Math.max(params.lesserAdjustedAmount -
            params.insurenceSettlementAllRefund -
            params.TotalAdjustmentcancellation, 0).toFixed(2));
        // params.amountFinancedTruncated = params
        // gap - calculation - end
        //new calculation - end
        /* if (params.actualAmountFinanced > C_HiddenValue) {
          adjusted_amount =
            C_HiddenValue -
            params.principalPaymentsReduction;
        } else {
          adjusted_amount =
            params.actualAmountFinanced -
            params.principalPaymentsReduction;
        }
        if (adjusted_amount > params.currentPayoff) {
          adjusted_amount = params.currentPayoff;
        }
        params.adjustedAmountFinanced = adjusted_amount;
        //GAP Calculation -3
        let gapValue = 0;
        let conditionalsum = 0;
        if (params.conditionAdjustmentA > 0) {
          conditionalsum += params.conditionAdjustmentA;
        }
        if (params.conditionAdjustmentB > 0) {
          conditionalsum += params.conditionAdjustmentB;
        }
        if (params.conditionAdjustmentC) {
          conditionalsum += params.conditionAdjustmentC;
        }
        gapValue =
          adjusted_amount -
          params.insuranceSettlementAllRefunds -
          conditionalsum;
        // console.log("GAP Value=" + gapValue);
        if (gapValue < 1) {
          gapValue = 0;
        }
        params.finalGapSettlement = gapValue; */
        return params;
    };
    ClaimService.prototype.pmt = function (rate_per_period, number_of_payments, present_value, future_value, type) {
        future_value = typeof future_value !== 'undefined' ? future_value : 0;
        type = typeof type !== 'undefined' ? type : 0;
        if (rate_per_period !== 0.0) {
            // Interest rate exists
            var q = Math.pow(1 + rate_per_period, number_of_payments);
            return (-(rate_per_period * (future_value + q * present_value)) /
                ((-1 + q) * (1 + rate_per_period * type)));
        }
        else if (number_of_payments !== 0.0) {
            // No interest rate, but number of payments exists
            return -(future_value + present_value) / number_of_payments;
        }
        return 0;
    };
    ClaimService.prototype.FV = function (rate, nper, pmt, pv, type) {
        var pow = Math.pow(1 + rate, nper);
        var fv;
        if (rate) {
            fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
        }
        else {
            fv = -1 * (pv + pmt * nper);
        }
        return fv.toFixed(2);
    };
    ClaimService.prototype.parseNumber = function (string) {
        if (string instanceof Error) {
            return string;
        }
        if (string === undefined || string === null || string === '') {
            return 0;
        }
        if (typeof string === 'boolean') {
            string = +string;
        }
        if (!isNaN(string)) {
            return parseFloat(string);
        }
        return string;
    };
    ClaimService.prototype.RATE = function (periods, payment, present, future, type, guess) {
        guess = guess === undefined ? 0.01 : guess;
        future = future === undefined ? 0 : future;
        type = type === undefined ? 0 : type;
        periods = this.parseNumber(periods);
        payment = this.parseNumber(payment);
        present = this.parseNumber(present);
        future = this.parseNumber(future);
        type = this.parseNumber(type);
        guess = this.parseNumber(guess);
        var epsMax = 1e-10;
        var iterMax = 20;
        var rate = guess;
        type = type ? 1 : 0;
        for (var i = 0; i < iterMax; i++) {
            if (rate <= -1) {
                return true;
            }
            var y = void 0, f = void 0;
            if (Math.abs(rate) < epsMax) {
                y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
            }
            else {
                f = Math.pow(1 + rate, periods);
                y = present * f + payment * (1 / rate + type) * (f - 1) + future;
            }
            if (Math.abs(y) < epsMax) {
                return rate;
            }
            var dy = void 0;
            if (Math.abs(rate) < epsMax) {
                dy = present * periods + payment * type * periods;
            }
            else {
                f = Math.pow(1 + rate, periods);
                var df = periods * Math.pow(1 + rate, periods - 1);
                dy = present * df + payment * (1 / rate + type) * df + payment * (-1 / (rate * rate)) * (f - 1);
            }
            rate -= y / dy;
        }
        return rate;
    };
    ;
    ClaimService.prototype.rate = function (periods, payment, present, future, type, guess) {
        guess = guess === undefined ? 0.01 : guess;
        future = future === undefined ? 0 : future;
        type = type === undefined ? 0 : type;
        // Set maximum epsilon for end of iteration
        var epsMax = 1e-10;
        // Set maximum number of iterations
        var iterMax = 10;
        // Implement Newton's method
        var y, y0, y1, x0, x1 = 0, f = 0, i = 0;
        var rate = guess;
        if (Math.abs(rate) < epsMax) {
            y =
                present * (1 + periods * rate) +
                    payment * (1 + rate * type) * periods +
                    future;
        }
        else {
            f = Math.exp(periods * Math.log(1 + rate));
            y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
        y0 = present + payment * periods + future;
        y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
        i = x0 = 0;
        x1 = rate;
        while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
            rate = (y1 * x0 - y0 * x1) / (y1 - y0);
            x0 = x1;
            x1 = rate;
            if (Math.abs(rate) < epsMax) {
                y =
                    present * (1 + periods * rate) +
                        payment * (1 + rate * type) * periods +
                        future;
            }
            else {
                f = Math.exp(periods * Math.log(1 + rate));
                y = present * f + payment * (1 / rate + type) * (f - 1) + future;
            }
            y0 = y1;
            y1 = y;
            ++i;
        }
        return rate;
    };
    ClaimService.prototype.getAllClaimStatus = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/analytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    /* products */
    ClaimService.prototype.getProducts = function () {
        return this.httpClient.get("/products").pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.addLease = function (payload) {
        return this.httpClient.post("/leaseadd", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.updateLease = function (claimId, payload) {
        return this.httpClient.post("/leaseupdate/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.leasechangeStatus = function (claimId, payload) {
        return this.httpClient.put("/lease/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getLeaseClaimsByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.dateOfLoss_at_from
            ? params.append('dateOfLoss_at_from', "" + payload.dateOfLoss_at_from)
            : params;
        params = payload.dateOfLoss_at_to
            ? params.append('dateOfLoss_at_to', "" + payload.dateOfLoss_at_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/leaseclaims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getLeaseClaimById = function (claimId) {
        return this.httpClient.get("/lease/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.acceptLeaseRequest = function (claimId, payload) {
        return this.httpClient.put("/lease/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.rejectLeaseRequest = function (claimId, payload) {
        return this.httpClient.put("/lease/" + claimId + "/rejectrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.deleteLeaseClaim = function (claimId) {
        return this.httpClient.delete("/lease/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getLeaseAnalytics = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/leaseanalytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.findClaim = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('contractNumber', "" + payload.contractNumber);
        }
        if (payload.vinNumber && payload.vinNumber !== '') {
            params = params.append('vinNumber', "" + payload.vinNumber);
        }
        if (payload.sequence && payload.sequence !== '') {
            params = params.append('sequence', "" + payload.sequence);
        }
        return this.httpClient.get("/findclaim", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getContractDetails = function (payload) {
        console.log(payload, 'payload');
        return this.directcall
            .post(environment.adminSystemURL + 'contract/listbyclaim', payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.prototype.getContractInfo = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('contractNo', "" + payload.contractNo);
        }
        if (payload.vinNumber && payload.vinNumber !== '') {
            params = params.append('vinNumber', "" + payload.vinNumber);
        }
        if (payload.productType && payload.productType !== '') {
            params = params.append('productType', "" + payload.productType);
        }
        return this.directcall
            .post(environment.adminSystemURL + 'contract/data', params)
            .pipe(map(function (body) {
            return body;
        }));
    };
    ClaimService.ngInjectableDef = i0.defineInjectable({ factory: function ClaimService_Factory() { return new ClaimService(i0.inject(i1.HttpClient), i0.inject(i1.HttpBackend)); }, token: ClaimService, providedIn: "root" });
    return ClaimService;
}());
export { ClaimService };
