import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KeyfechService = /** @class */ (function () {
    function KeyfechService(httpClient) {
        this.httpClient = httpClient;
        this.claimData = null;
        this.claimData$ = new EventEmitter();
    }
    KeyfechService.prototype.add = function (payload) {
        return this.httpClient.post("/keyfetchadd", payload).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.update = function (claimId, payload) {
        return this.httpClient.post("/keyfetchupdate/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.getByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.claimDate_from
            ? params.append('claimDate_from', "" + payload.claimDate_from)
            : params;
        params = payload.claimDate_to
            ? params.append('claimDate_to', "" + payload.claimDate_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/keyfetchclaims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.getById = function (claimId) {
        return this.httpClient.get("/keyfetch/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.acceptRequest = function (claimId, payload) {
        return this.httpClient.put("/keyfetch/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.rejectRequest = function (claimId, payload) {
        return this.httpClient.put("/keyfetch/" + claimId + "/rejectrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.changeStatus = function (claimId, payload) {
        return this.httpClient.put("/keyfetch/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.deleteClaim = function (claimId) {
        return this.httpClient.delete("/keyfetch/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.prototype.getAnalytics = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/keyfetchanalytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    KeyfechService.ngInjectableDef = i0.defineInjectable({ factory: function KeyfechService_Factory() { return new KeyfechService(i0.inject(i1.HttpClient)); }, token: KeyfechService, providedIn: "root" });
    return KeyfechService;
}());
export { KeyfechService };
