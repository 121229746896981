<form [formGroup]="confirmForm" (ngSubmit)="approveClaim()">
    <div class="modal-header">
        <h4 class="modal-title">Approve Claim</h4>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <p class="col-12 mb-0">
            </p>
        </div>
        <!-- <div class="form-group row">
            <app-required-indication class="col-12" [control]="confirmForm.controls['message']" [label]="'Message'">
            </app-required-indication>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="confirmForm.get('message')" [controlName]="'Enter Message'" [apiErrorType]="'message'">
                    <textarea type="text" name="reason" rows="5" class="form-control" formControlName="message"></textarea>
                </app-form-error-wrapper>
            </div>
        </div> -->
        <div class="form-group row">
            <app-required-indication class="col-12" [control]="confirmForm.controls['amountApproved']"
                [label]="'Approved Amount'">
            </app-required-indication>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="confirmForm.get('amountApproved')"
                    [controlName]="'Enter amountApproved'" [apiErrorType]="'amountApproved'">
                    <input type="text" name="amountApproved" class="form-control" formControlName="amountApproved"
                        placeholder="$0.00" currencyMask [options]="{ allowNegative: false }" autocomplete="off" />
                </app-form-error-wrapper>
            </div>
        </div>

        <div class="form-group row" style="display:none">
            <label class="col-12">Admin Id ?</label>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="confirmForm.get('adminId')"
                    [controlName]="'Enter adminId'" [apiErrorType]="'adminId'">
                    <input type="text" name="adminId" class="form-control" formControlName="adminId">
                </app-form-error-wrapper>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button class="btn btn-primary" type="submit">
            <app-button-loader [label]="'Approve Claim'"></app-button-loader>
        </button>
    </div>
</form>