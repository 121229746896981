<div class="row agent-container">
  <div class="col-12">
    <div class="card" *ngIf="configEditForm">
      <form [formGroup]="configEditForm" (ngSubmit)="updateConfig()" class="form-horizontal form-material"
        id="configEditForm" novalidate>
        <div class="card-header secondary-background">
          System Config
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['website']" [label]="'Website'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['website']"
                    [controlName]="'website'" [apiErrorType]="'website'">
                    <input class="form-control" type="text" formControlName="website" placeholder="Enter wesite name (www.test.com)" />
                  </app-form-error-wrapper>
                </div>
              </div>

              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['portal']" [label]="'Web portal'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['portal']"
                    [controlName]="'portal'" [apiErrorType]="'portal'">
                    <input class="form-control" type="text" formControlName="portal" placeholder="Enter site portal address (test.com)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['appLogo']" [label]="'Logo Url'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['appLogo']"
                    [controlName]="'Logo Url'" [apiErrorType]="'appLogo'">
                    <input class="form-control" type="text" formControlName="appLogo" placeholder="Enter logo url" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['senderMail']" [label]="'Mail sender'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['senderMail']"
                    [controlName]="'sender Mail'" [apiErrorType]="'senderMail'">
                    <input class="form-control" type="text" formControlName="senderMail" placeholder="Enter Sender Mail (GAP Claim<claim@portal.com>)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['notificationSenderMail']" [label]="'Notifcation Mail sender'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['notificationSenderMail']"
                    [controlName]="'Notifcation Mail sender'" [apiErrorType]="'notificationSenderMail'">
                    <input class="form-control" type="text" formControlName="notificationSenderMail" placeholder="Enter Notifcation Sender Mail (GAP Claim<claim@portal.com>)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['supportMail']" [label]="'Support Mail'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['supportMail']"
                    [controlName]="'support Mail'" [apiErrorType]="'supportMail'">
                    <input class="form-control" type="text" formControlName="supportMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['HelpMail']" [label]="'Help Mail'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['HelpMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'HelpMail'">
                    <input class="form-control" type="text" formControlName="HelpMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['notifyClaimMail']" [label]="'Claim complete notification'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['notifyClaimMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'notifyClaimMail'">
                    <input class="form-control" type="text" formControlName="notifyClaimMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['dealersNotifyMail']" [label]="'Dealers consolidate reports To'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['dealersNotifyMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'dealersNotifyMail'">
                    <input class="form-control" type="text" formControlName="dealersNotifyMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="configEditForm.get('notifyToDefault')" [label]="'Default Claim Mail'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div class="custom-control custom-checkbox ">
                      <input id="notifyToDefault" class="custom-control-input" formControlName="notifyToDefault"
                        [checked]="configEditForm.get('notifyToDefault').value"
                        (click)="selectCheckbox('notifyToDefault', $event)" type="checkbox" />
                      <label for="notifyToDefault" class="custom-control-label pos-abs"></label>
                    </div>
                  </div>
                </div>
                <div class="form-group m-xl-0 col-12 col-xl-9 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-4 text-xl-center">
                    <app-required-indication [control]="configEditForm.controls['defaultMail']" [label]="'Default Mail Id'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-8">
                    <app-form-error-wrapper [control]="configEditForm.controls['defaultMail']" [controlName]="'defaultMail'"
                      [apiErrorType]="'defaultMail'">
                      <input class="form-control" type="text" formControlName="defaultMail" />
                    </app-form-error-wrapper>
                  </div>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="configEditForm.get('MAIL_FLAG')" [label]="'Mail Flag (Re-submission mail)'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div class="custom-control custom-checkbox ">
                      <input id="MAIL_FLAG" class="custom-control-input" formControlName="MAIL_FLAG"
                        [checked]="configEditForm.get('MAIL_FLAG').value"
                        (click)="selectCheckbox('MAIL_FLAG', $event)" type="checkbox" />
                      <label for="MAIL_FLAG" class="custom-control-label pos-abs"></label>
                    </div>
                  </div>
                </div>
               
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['MailFooter']" [label]="'Mail template footer'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['MailFooter']"
                    [controlName]="'Mail Footer'" [apiErrorType]="'MailFooter'">
                    <input class="form-control" type="text" formControlName="MailFooter" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="configEditForm.controls['stoneEagleAPI']" [label]="'Stone Eagle API'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="configEditForm.controls['stoneEagleAPI']"
                    [controlName]="'Stone Eagle API'" [apiErrorType]="'stoneEagleAPI'">
                    <input class="form-control" type="text" formControlName="stoneEagleAPI" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 d-xl-flex align-items-center p-0">
                  Note: <small>If you want enter two more mail ids use seperation of comma(,).</small>
                  </div>
                  </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button class="btn btn-primary primary-background" [disabled]="
                  !configEditForm.dirty ||
                  configEditForm.invalid ||
                  isLoading
                ">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Config'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Edit Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>