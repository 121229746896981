<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="leaseCreateForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="leaseCreateForm" novalidate>
        <div class="card-header">
          Lease Wear Tear Portal
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="row">
                <div class="col-12" *ngIf="loggedInUserType == 'customer'">
                  <h3 class="title-case">Find my contract</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 col-xl-6 d-xl-flex">
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                              formControlName="filter1select">
                              <option value="">Select Field</option>
                              <option value="firstname">First Name</option>
                              <option value="lastname">Last Name</option>
                              <option value="phone" [disabled]="leaseCreateForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                              <option value="vin" [disabled]="leaseCreateForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                            </select>           
                            <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                            <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                      </div>
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                              formControlName="filter2select">
                              <option value="" >Select Field</option>
                              <option value="contract">Contract No</option>
                              <option value="vin" [disabled]="leaseCreateForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                              <option value="phone" [disabled]="leaseCreateForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                            </select>                   
                            <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                        <button
                          type="button"
                          (click)="getMyContract()"
                          class="btn btn-info primary-background mr-2 mb-0"
                        >
                        <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                        Search & Fill
                        </button>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="show_claims">
                <div class="col-12">
                  <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
                  <div class="row">
                    <div class="col-12 d-xl-flex pl-5 pr-5">
                      <div class="table table-responsive smart-table">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Contract No</th>
                              <th>VIN</th>
                              <!--<th>Name</th>
                              <th>Phone No</th>
                              <th>Dealer</th> -->
                              <th>Product</th>
                              <th>Year</th>
                              <th>Make</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let claim of claims_data; let i=index"
                            >
                              <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                              <td>{{ claim.ContractNumber }}</td>
                              <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                              <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                              <td>{{ claim.PhysicalPhone | phone }}</td>
                              <td>{{ claim.DealerName }}</td> -->
                              <td>{{ claim.Product }}</td>
                              <td>{{ claim.VehicleYear }}</td>
                              <td>{{ claim.VehicleMake }}</td>
                              <td>{{ claim.VehicleModel }}</td>
                            </tr>
                          </tbody>
                        </table>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>        
              <hr class="mt-0" *ngIf="loggedInUserType == 'customer'"/>
              <h3 class="title-case">Customer information </h3>
              <div class="col-12 p-0">
                <!-- <div class="form-group row ml-0 mr-0">
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="leaseCreateForm.get('contractNumber')"
                        [label]="'Contract Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('contractNumber')"
                        [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                        <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('dateOfLoss_at')"
                        [label]="'Date Leased Vehicle Returned'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 pos-rel">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dateOfLoss_at')"
                        [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'dateOfLoss_at'">
                        <input type="text" name="dateLoss" formControlName="dateOfLoss_at" placeholder="MM/DD/YYYY"
                          ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                          [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(leaseCreateForm, 'dateOfLoss_at')" placement="left"
                        ngbTooltip="Remove" *ngIf="leaseCreateForm.controls['dateOfLoss_at'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div> -->

                <div class="form-group row ml-0 mr-0">
                  <div class="form-group m-xl-0 col-12 col-xl-5 d-xl-flex">
                    <label class="col-12 col-xl-5 p-0"><app-required-indication [control]="leaseCreateForm.get('contractNumber')"
                      [label]="'Contract Number'"></app-required-indication>
                      <!-- <i class="fa fa-question-circle"
                      title="It helps you to pre-fill the basic contract details"
                    ></i> -->
                  </label>
                  <div class="col-12 p-0"
                  [ngClass]="{
                    'col-xl-7': loggedInUserType == 'customer',
                    'col-xl-6': loggedInUserType != 'customer'
                  }">
                    <app-form-error-wrapper [control]="leaseCreateForm.get('contractNumber')"
                      [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                      <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                    </app-form-error-wrapper>
                  </div>
                  <div class="col-12 col-xl-1" *ngIf="loggedInUserType != 'customer'">
                    <button
                    type="button"
                    (click)="getContract()"
                    class="btn btn-info mr-2 primary-background"
                  >
                    Fill
                  </button>
                  </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-4 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-6">
                      <app-required-indication [control]="leaseCreateForm.get('dateOfLoss_at')"
                        [label]="'Date Leased Vehicle Returned'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 pos-rel">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dateOfLoss_at')"
                        [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'dateOfLoss_at'">
                        <input type="text" name="dateLoss" formControlName="dateOfLoss_at" placeholder="MM/DD/YYYY"
                          ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                          [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(leaseCreateForm, 'dateOfLoss_at')" placement="left"
                        ngbTooltip="Remove" *ngIf="leaseCreateForm.controls['dateOfLoss_at'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-5 p-0 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('vehiclePurchased')"
                        [label]="'Vehicle Purchased'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 pl-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('vehiclePurchased')"
                        [controlName]="'Vehicle Purchased'" [apiErrorType]="'vehiclePurchased'">
                        <select class="custom-select form-control" name="vahiclePurchased"
                          formControlName="vehiclePurchased">
                          <option value="N">New</option>
                          <option value="U">Used</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('dealer')" [label]="'Dealer'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealer')" [controlName]="'Dealer'"
                      [apiErrorType]="'dealer'">
                      <ng-template #rt let-r="result" let-t="term">
                        {{ r.name }}
                      </ng-template>
                      <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                        formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                        placeholder="Search and select Dealer" name="dealer" />
                    </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('customerName')"
                        [label]="'Customer Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-ls-xl-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('customerName')"
                        [controlName]="'Customer Name'" [apiErrorType]="'customerName'">
                        <input type="text" name="customerName" class="form-control" formControlName="customerName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5">
                      <app-required-indication [control]="leaseCreateForm.get('phoneNumber')" [label]="'Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-ls-xl-0 p-0">
                      <app-form-error-wrapper
                      [control]="leaseCreateForm.get('phoneNumber')"
                      [controlName]="'Phone Number'"
                      [apiErrorType]="'phoneNumber'"
                    >
                      <input
                        type="tel"
                        name="phonenumber"
                        mask="000-000-0000"
                        class="form-control"
                        [formControl]="leaseCreateForm.controls['phoneNumber']"
                      />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="leaseCreateForm.get('email')" [label]="'Email'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3">
                    <app-form-error-wrapper [control]="leaseCreateForm.get('email')" [controlName]="'Email'"
                      [apiErrorType]="'email'">
                      <input type="email" name="email" class="form-control" formControlName="email" />
                    </app-form-error-wrapper>
                  </div>
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="leaseCreateForm.get('streetAddress')"
                      [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-5">
                    <app-form-error-wrapper [control]="leaseCreateForm.get('streetAddress')"
                      [controlName]="'Street Address'" [apiErrorType]="'streetAddress'">
                      <input type="text" name="street-address" class="form-control" formControlName="streetAddress" />
                    </app-form-error-wrapper>
                  </div>     
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex">
                    <label class="col-12 col-xl-5 pl-0">
                      <app-required-indication [control]="leaseCreateForm.get('city')" [label]="'City'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('city')" [controlName]="'City'"
                        [apiErrorType]="'city'">
                        <input type="text" name="city" class="form-control" formControlName="city" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('state')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('state')" [controlName]="'State'"
                        [apiErrorType]="'state'">
                        <select class="custom-select form-control" name="state" formControlName="state">
                          <option value="">Select State</option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('zipCode')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('zipCode')" [controlName]="'Zip Code'"
                        [apiErrorType]="'zipCode'">
                        <input type="tel" class="form-control" [formControl]="leaseCreateForm.controls['zipCode']"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

               </div>
               <br/><br/>
               
              <h3 class="title-case">Dealership Information </h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="leaseCreateForm.get('dealershipConatactAtName')"
                      [label]="'Contact Name at Dealership'"></app-required-indication>
                  </label>
                  <div class="col-12 col-xl-10">
                    <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipConatactAtName')"
                      [controlName]="'Contact Name at Dealership'" [apiErrorType]="'dealershipConatactAtName'">
                      <input type="text" name="dealershipConatactAtName" class="form-control"
                        formControlName="dealershipConatactAtName" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="leaseCreateForm.get('dealershipEmail')"
                        [label]="'Contact Email Dealership'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipEmail')"
                        [controlName]="'Contact Email Dealership'" [apiErrorType]="'dealershipEmail'">
                        <input type="text" name="dealershipEmail" class="form-control"
                          formControlName="dealershipEmail" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="leaseCreateForm.get('dealershipPhoneNumber')"
                        [label]="'Dealership Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipPhoneNumber')"
                        [controlName]="'Phone Number'" [apiErrorType]="'dealershipPhoneNumber'">
                        <input type="tel" name="phonenumber" mask="000-000-0000" class="form-control"
                          [formControl]="leaseCreateForm.controls['dealershipPhoneNumber']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="leaseCreateForm.get('dealershipAddress')"
                      [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-10">
                    <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipAddress')"
                      [controlName]="'Street Address'" [apiErrorType]="'dealershipAddress'">
                      <input type="text" name="street-address" class="form-control"
                        formControlName="dealershipAddress" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('dealershipCity')" [label]="'City'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipCity')" [controlName]="'City'"
                        [apiErrorType]="'dealershipCity'">
                        <input type="text" class="form-control" formControlName="dealershipCity" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('dealershipState')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipState')" [controlName]="'State'"
                        [apiErrorType]="'dealershipState'">
                        <select class="custom-select form-control" name="state" formControlName="dealershipState">
                          <option value="">Select State </option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('dealershipZipCode')"
                        [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('dealershipZipCode')"
                        [controlName]="'Zip Code'" [apiErrorType]="'dealershipZipCode'">
                        <input type="tel" name="dealershipZipCode" class="form-control"
                          [formControl]="leaseCreateForm.controls['dealershipZipCode']" minlength="5" maxlength="5"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/>
              <h3 class="title-case">Vehicle Information</h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-8 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('vinNumber')" [label]="'VIN Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-ls-xl-0 pl-1">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('vinNumber')" [controlName]="'VIN Number'"
                        [apiErrorType]="'vinNumber'">
                        <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                          [formControl]="leaseCreateForm.get('vinNumber')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('currentMileage')"
                        [label]="'Current Mileage'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper
                      [control]="leaseCreateForm.get('currentMileage')"
                      [controlName]="'Current mileage'"
                      [apiErrorType]="'currentMileage'"
                    >
                      <input
                        type="text"
                        name="currentMileage"
                        class="form-control"
                        mask="000000"
                        [formControl]="leaseCreateForm.get('currentMileage')"
                      />
                    </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="leaseCreateForm.get('year')" [label]="'Year'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-ls-xl-0 pl-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('year')" [controlName]="'Year'"
                        [apiErrorType]="'year'">
                        <input type="text" name="year" mask="0000" class="form-control"
                          [formControl]="leaseCreateForm.get('year')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('make')" [label]="'Make'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('make')" [controlName]="'Make'"
                        [apiErrorType]="'make'">
                        <input type="text" name="make" class="form-control"
                          [formControl]="leaseCreateForm.get('make')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="leaseCreateForm.get('model')" [label]="'Model'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="leaseCreateForm.get('model')" [controlName]="'Model'"
                        [apiErrorType]="'model'">
                        <input type="text" name="model" class="form-control" formControlName="model" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/>
              <h3 class="title-case">Customer Documents Needed</h3>
              <div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5 p-0">
                    <app-required-indication [control]="leaseCreateForm.get('isVehicleReturn')"
                      [label]="'Did you return the vehicle to your selling dealer?'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3 p-ls-xl-0">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="onsite-yes" name="isVehicleReturn" class="custom-control-input"
                          formControlName="isVehicleReturn" [value]="true" />
                        <label class="custom-control-label mr-2" for="onsite-yes">Yes</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="onsite-no" name="isVehicleReturn" class="custom-control-input"
                          formControlName="isVehicleReturn" [value]="false" checked />
                        <label class="custom-control-label mr-2" for="onsite-no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5 p-0 pad-res">
                    <app-required-indication [control]="leaseCreateForm.get('isVehicleInspected')"
                      [label]="'Has your vehicle been inspected by the leasing company prior to turning it in?'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3 p-ls-xl-0">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="onff-yes" name="isVehicleInspected" class="custom-control-input"
                          formControlName="isVehicleInspected" [value]="true" />
                        <label class="custom-control-label mr-2" for="onff-yes">Yes</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="onff-no" name="isVehicleInspected" class="custom-control-input"
                          formControlName="isVehicleInspected" [value]="false" checked />
                        <label class="custom-control-label mr-2" for="onff-no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('leaseExpireDate')"
                      [label]="'What date does your lease expire?'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-2">
                    <app-form-error-wrapper
                    [control]="leaseCreateForm.get('leaseExpireDate')"
                    [controlName]="'Last Paid Payment Date'"
                    [apiErrorType]="'leaseExpireDate'"
                  >
                    <input
                      type="text"
                      name="leaseExpireDate"
                      formControlName="leaseExpireDate"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d1="ngbDatepicker"
                      class="form-control"
                      (click)="d1.toggle()"
                      [readonly]="true"
                      [minDate]="{ year: 1900, month: 1, day: 1 }"
                      [maxDate]="{
                        year: utilService.getCurrentYear+10,
                        month: utilService.getCurrentMonth,
                        day: utilService.getCurrentDay
                      }"
                    />
                  </app-form-error-wrapper>
                  </div>  
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('damangeReport')"
                      [label]="
                        'Please upload your damage report'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.damangeReport">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.damangeReport.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.damangeReport
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('damangeReport')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.damangeReport">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'damangeReport')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('damangeReport').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('damangeReport').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div> 
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('contractDoc')"
                      [label]="
                        'Please upload your Lease Wear & Tear contract'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.contractDoc">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.contractDoc.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.contractDoc
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('contractDoc')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.contractDoc">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'contractDoc')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('contractDoc').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('contractDoc').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('leaseAgreement')"
                      [label]="
                        'Please upload your original lease agreement'
                      "
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.leaseAgreement">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.leaseAgreement.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.leaseAgreement
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('leaseAgreement')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.leaseAgreement">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'leaseAgreement')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('leaseAgreement').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('leaseAgreement').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('odometerStatement')"
                      [label]="
                        'Please upload the odometer statement/receipt you received when returning your vehicle to your selling dealer'
                      "
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.odometerStatement">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.odometerStatement.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.odometerStatement
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('odometerStatement')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.odometerStatement">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'odometerStatement')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('odometerStatement').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('odometerStatement').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('physicalDamage')"
                      [label]="
                        'Please upload any photos of physical damage to your vehicle'
                      "
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.physicalDamage">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.physicalDamage.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.physicalDamage
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('physicalDamage')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.physicalDamage">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'physicalDamage')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('physicalDamage').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('physicalDamage').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="leaseCreateForm.get('additionalDocuments')"
                      [label]="
                        'Please upload any additional documents either requested by the administrator or that you would like to provide'
                      "
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.additionalDocuments">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.additionalDocuments.replace('uploads/', '').replace('lease/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.additionalDocuments
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('additionalDocuments')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.additionalDocuments">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'additionalDocuments')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="leaseCreateForm.get('additionalDocuments').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          leaseCreateForm.get('additionalDocuments').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button
              type="button"
              (click)="cancelClick()"
              class="btn btn-secondary mr-2"
            >
              Cancel
            </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="leaseCreateForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Claim'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="leaseCreateForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Claim'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>