import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_VAR, matchValidation, UtilService } from '@app/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '@app/core/service/profile.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loggedInUserType: any = null;
  user: Authentication.User;
  private unsubscribe: Subject<void> = new Subject();
  changePasswordForm: FormGroup;
  isLoading = false;
  queryParams: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private toastrService: ToastrService,
    public utilService: UtilService
  ) {
    this.user = this.authenticationService.credentials.user;
    localStorage.setItem(LOCAL_VAR.selectedProduct, null);
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
      });
    this.loggedInUserType = this.user.userRoleId.slug;
  }

  ngOnInit() {
    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
      this.createForm();
    });
  }

  private createForm() {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentpassword: ['', [Validators.required,Validators.minLength(8)]],
        newpassword: ['', [Validators.required,Validators.minLength(8)]],
        confirmpassword: ['',[Validators.required,Validators.minLength(8)]]
      },
      { validator: matchValidation('newpassword', 'confirmpassword') }
    );
  }

  changePassword() {
    this.isLoading = true;
    let payload: any;
    payload = {
      oPassword: this.changePasswordForm.value.currentpassword,
      password: this.changePasswordForm.value.newpassword,
      cPassword: this.changePasswordForm.value.confirmpassword
    };

    this.profileService
      .updatePassword(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.toastrService.info('Password updated Successfully');
        this.router.navigate(['/profile']);
      });
  }

  
  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }
}
