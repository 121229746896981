<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="shieldForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="shieldForm" novalidate>
        <div class="card-header">
          360 Shield Claim
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="row">
                <div class="col-12" *ngIf="loggedInUserType == 'customer'">
                  <h3 class="title-case">Find my contract</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 col-xl-6 d-xl-flex">
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                              formControlName="filter1select">
                              <option value="">Select Field</option>
                              <option value="firstname">First Name</option>
                              <option value="lastname">Last Name</option>
                              <option value="phone" [disabled]="shieldForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                              <option value="vin" [disabled]="shieldForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                            </select>           
                            <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                            <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                      </div>
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                              formControlName="filter2select">
                              <option value="" >Select Field</option>
                              <option value="contract">Contract No</option>
                              <option value="vin" [disabled]="shieldForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                              <option value="phone" [disabled]="shieldForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                            </select>                   
                            <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                        <button
                          type="button"
                          (click)="getMyContract()"
                          class="btn btn-info primary-background mr-2 mb-0"
                        >
                        <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                        Search & Fill
                        </button>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="show_claims">
                <div class="col-12">
                  <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
                  <div class="row">
                    <div class="col-12 d-xl-flex pl-5 pr-5">
                      <div class="table table-responsive smart-table">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Contract No</th>
                              <th>VIN</th>
                              <!--<th>Name</th>
                              <th>Phone No</th>
                              <th>Dealer</th> -->
                              <th>Product</th>
                              <th>Year</th>
                              <th>Make</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let claim of claims_data; let i=index"
                            >
                              <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                              <td>{{ claim.ContractNumber }}</td>
                              <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                              <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                              <td>{{ claim.PhysicalPhone | phone }}</td>
                              <td>{{ claim.DealerName }}</td> -->
                              <td>{{ claim.Product }}</td>
                              <td>{{ claim.VehicleYear }}</td>
                              <td>{{ claim.VehicleMake }}</td>
                              <td>{{ claim.VehicleModel }}</td>
                            </tr>
                          </tbody>
                        </table>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>        
              <hr class="mt-0" *ngIf="loggedInUserType == 'customer'" />
              <h3 class="title-case">Customer information </h3>
              <div class="col-12 p-0">
               <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="shieldForm.get('contractNumber')"
                        [label]="'Contract Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 pl-0"
                    [ngClass]="{
                      'col-xl-7': loggedInUserType == 'customer',
                      'col-xl-6': loggedInUserType != 'customer'
                    }"
                    >
                      <app-form-error-wrapper [control]="shieldForm.get('contractNumber')"
                        [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                        <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                      </app-form-error-wrapper>
                    </div>
                    <div class="col-12 col-xl-1 pl-0"  *ngIf="loggedInUserType != 'customer'">
                      <button
                      type="button"
                      (click)="getContract()"
                      class="btn btn-info mr-2 primary-background"
                    >
                      Fill
                    </button>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="shieldForm.get('claimDate')"
                        [label]="'Claim Date'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 pos-rel">
                      <app-form-error-wrapper [control]="shieldForm.get('claimDate')"
                        [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                        <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                          ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                          [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(shieldForm, 'claimDate')" placement="left"
                        ngbTooltip="Remove" *ngIf="shieldForm.controls['claimDate'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('dealer')" [label]="'Selling Dealer'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('dealer')" [controlName]="'Dealer'"
                      [apiErrorType]="'dealer'">
                      <ng-template #rt let-r="result" let-t="term">
                        {{ r.name }}
                      </ng-template>
                      <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                        formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                        placeholder="Search and select Dealer" name="dealer" />
                    </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="shieldForm.get('customerName')"
                        [label]="'Customer Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-ls-xl-0">
                      <app-form-error-wrapper [control]="shieldForm.get('customerName')"
                        [controlName]="'Customer Name'" [apiErrorType]="'customerName'">
                        <input type="text" name="customerName" class="form-control" formControlName="customerName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5">
                      <app-required-indication [control]="shieldForm.get('phoneNumber')" [label]="'Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-ls-xl-0">
                      <app-form-error-wrapper
                      [control]="shieldForm.get('phoneNumber')"
                      [controlName]="'Phone Number'"
                      [apiErrorType]="'phoneNumber'"
                    >
                      <input
                        type="tel"
                        name="phonenumber"
                        mask="000-000-0000"
                        class="form-control"
                        [formControl]="shieldForm.controls['phoneNumber']"
                      />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('email')" [label]="'Email'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('email')" [controlName]="'Email'"
                        [apiErrorType]="'email'">
                        <input type="email" name="email" class="form-control" formControlName="email" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="shieldForm.get('streetAddress')"
                      [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-6">
                    <app-form-error-wrapper [control]="shieldForm.get('streetAddress')"
                      [controlName]="'Street Address'" [apiErrorType]="'streetAddress'">
                      <input type="text" name="street-address" class="form-control" formControlName="streetAddress" />
                    </app-form-error-wrapper>
                  </div>
                    <label class="col-12 col-xl-1">
                      <app-required-indication [control]="shieldForm.get('city')" [label]="'City'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-3">
                      <app-form-error-wrapper [control]="shieldForm.get('city')" [controlName]="'City'"
                        [apiErrorType]="'city'">
                        <input type="text" name="city" class="form-control" formControlName="city" />
                      </app-form-error-wrapper>
                    </div>           
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="shieldForm.get('state')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('state')" [controlName]="'State'"
                        [apiErrorType]="'state'">
                        <select class="custom-select form-control" name="state" formControlName="state">
                          <option value="">Select State</option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('zipCode')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('zipCode')" [controlName]="'Zip Code'"
                        [apiErrorType]="'zipCode'">
                        <input type="tel" class="form-control" [formControl]="shieldForm.controls['zipCode']"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

               </div>
               <br/><br/>
              <h3 class="title-case">Repair Shop Information</h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="shieldForm.get('repairshopContactName')"
                        [label]="'Contact Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="shieldForm.get('repairshopContactName')"
                        [controlName]="'Contact Name'" [apiErrorType]="'repairshopContactName'">
                        <input type="text" name="repairshopContactName" class="form-control"
                          formControlName="repairshopContactName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="shieldForm.get('repairshopName')"
                        [label]="'Repair Shop Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="shieldForm.get('repairshopName')"
                        [controlName]="'Repair Shop Name'" [apiErrorType]="'repairshopName'">
                        <input type="text" name="repairshopName" class="form-control"
                          formControlName="repairshopName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">                      
                      <app-required-indication [control]="shieldForm.get('repairshopPhoneNumber')"
                        [label]="'Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper
                      [control]="shieldForm.get('repairshopPhoneNumber')"
                      [controlName]="'repairshopPhoneNumber'"
                      [apiErrorType]="'repairshopPhoneNumber'"
                    >
                      <input
                        type="tel"
                        name="repairshopPhoneNumber"
                        mask="000-000-0000"
                        class="form-control"
                        [formControl]="shieldForm.controls['repairshopPhoneNumber']"
                      />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="shieldForm.get('repairshopEmail')"
                        [label]="'Email'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="shieldForm.get('repairshopEmail')"
                        [controlName]="'Email'" [apiErrorType]="'repairshopEmail'">
                        <input type="text" name="repairshopEmail" class="form-control"
                          formControlName="repairshopEmail" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="shieldForm.get('repairshopAddress')"
                      [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-6">
                    <app-form-error-wrapper [control]="shieldForm.get('repairshopAddress')"
                      [controlName]="'Street Address'" [apiErrorType]="'repairshopAddress'">
                      <input type="text" name="street-address" class="form-control"
                        formControlName="repairshopAddress" />
                    </app-form-error-wrapper>
                  </div>
                  <label class="col-12 col-xl-1">
                    <app-required-indication [control]="shieldForm.get('repairshopCity')" [label]="'City'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3">
                    <app-form-error-wrapper [control]="shieldForm.get('repairshopCity')" [controlName]="'City'"
                      [apiErrorType]="'repairshopCity'">
                      <input type="text" class="form-control" formControlName="repairshopCity" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="shieldForm.get('repairshopState')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('repairshopState')" [controlName]="'State'"
                        [apiErrorType]="'repairshopState'">
                        <select class="custom-select form-control" name="state" formControlName="repairshopState">
                          <option value="">Select State </option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('repairshopZipCode')"
                        [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('repairshopZipCode')"
                        [controlName]="'Zip Code'" [apiErrorType]="'repairshopZipCode'">
                        <input type="tel" name="repairshopZipCode" class="form-control"
                          [formControl]="shieldForm.controls['repairshopZipCode']" minlength="5" maxlength="5"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/>
              <h3 class="title-case">Vehicle Information</h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="shieldForm.get('vinNumber')" [label]="'VIN Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="shieldForm.get('vinNumber')" [controlName]="'VIN Number'"
                        [apiErrorType]="'vinNumber'">
                        <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                          [formControl]="shieldForm.get('vinNumber')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>                  
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="shieldForm.get('currentMileage')"
                        [label]="'Current Mileage'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper
                      [control]="shieldForm.get('currentMileage')"
                      [controlName]="'Current mileage'"
                      [apiErrorType]="'currentMileage'"
                    >
                      <input
                        type="text"
                        name="currentMileage"
                        class="form-control"
                        mask="000000"
                        [formControl]="shieldForm.get('currentMileage')"
                      />
                    </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="shieldForm.get('year')" [label]="'Year'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="shieldForm.get('year')" [controlName]="'Year'"
                        [apiErrorType]="'year'">
                        <input type="text" name="year" mask="0000" class="form-control"
                          [formControl]="shieldForm.get('year')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('make')" [label]="'Make'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('make')" [controlName]="'Make'"
                        [apiErrorType]="'make'">
                        <input type="text" name="make" class="form-control"
                          [formControl]="shieldForm.get('make')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="shieldForm.get('model')" [label]="'Model'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="shieldForm.get('model')" [controlName]="'Model'"
                        [apiErrorType]="'model'">
                        <input type="text" name="model" class="form-control" formControlName="model" />
                      </app-form-error-wrapper>
                    </div>
                  </div>                  
                </div>
              </div>
              <br/><br/>
              <h3 class="title-case">Customer Documents Needed</h3>
              <div>
              <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="shieldForm.get('dealerInvoice')"
                      [label]="
                        'Dealer invoice stating that product was professionally and properly applied'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.dealerInvoice">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.dealerInvoice.replace('uploads/', '').replace('shield/', '')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.dealerInvoice
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('dealerInvoice')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.dealerInvoice">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'dealerInvoice')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="shieldForm.get('dealerInvoice').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          shieldForm.get('dealerInvoice').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="shieldForm.get('repairOrderInvoice')"
                      [label]="
                        'Repair Order Invoice detailing specific damages and affected areas'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.repairOrderInvoice">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.repairOrderInvoice.replace('uploads/', '').replace('shield/', '')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.repairOrderInvoice
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('repairOrderInvoice')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.repairOrderInvoice">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'repairOrderInvoice')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="shieldForm.get('repairOrderInvoice').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          shieldForm.get('repairOrderInvoice').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="shieldForm.get('paintThicknessPhoto')"
                      [label]="
                        'Photo of paint thickness measurements of any factory painted surface requiring repair'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.paintThicknessPhoto">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.paintThicknessPhoto.replace('uploads/', '').replace('shield/', '')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.paintThicknessPhoto
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('paintThicknessPhoto')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.paintThicknessPhoto">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'paintThicknessPhoto')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="shieldForm.get('paintThicknessPhoto').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          shieldForm.get('paintThicknessPhoto').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-5">
                    <app-required-indication
                      [control]="shieldForm.get('customerStatement')"
                      [label]="
                        'Statement from customer on how damage occurred'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-7">
                    <div *ngIf="uploadedImg.customerStatement">
                      <label class="col-12 col-xl-9 float-left">{{
                        uploadedImg.customerStatement.replace('uploads/', '').replace('shield/', '')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            uploadedImg.customerStatement
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('customerStatement')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!uploadedImg.customerStatement">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'customerStatement')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="shieldForm.get('customerStatement').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          shieldForm.get('customerStatement').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>

                 
                 <div formArrayName="vehiclePhotos" *ngIf="vehiclePhotos">
                  <div class="form-group d-xl-flex align-items-center">
                    <label class="col-12">
                      <app-required-indication
                        [label]="
                          'Minimum of 2 photos of each affected area of the vehicle'"
                      >
                      </app-required-indication>
                    </label>
                  </div>
                  <div *ngFor="let item of vehiclePhotos.controls; let i = index">
                    <div [formGroupName]="i">
                      <div class="form-group d-xl-flex align-items-center">
                        <div
                          class="form-group m-xl-0 col-12 col-xl-7 d-xl-flex align-items-center p-0"
                        >
                          <label class="col-12 col-xl-2 text-xl-center">
                            <app-required-indication
                              [control]="item.get('url')"
                              [label]="'Photo'"
                            ></app-required-indication>
                          </label>
                          <div class="col-12 col-xl-10">
                            <input
                              type="file"
                              *ngIf="!showvehiclePhoto[i]"
                              (change)="onChangeFile($event, i)"
                              name="url"
                              value="vehiclePhoto[i]"
                            />
                            <div *ngIf="showvehiclePhoto[i]">
                              <label class="m-r-10">{{
                                vehiclePhoto[i] &&
                                  vehiclePhoto[i].substr(
                                    vehiclePhoto[i].lastIndexOf('/') + 1
                                  )
                              }}</label>
                              <a
                                class="ng2-smart-action ng2-smart-action-edit-edit"
                                placement="right"
                                ngbTooltip="View Request"
                                href="{{ baseUrl }}{{
                                  vehiclePhoto[i]
                                }}"
                                target="_blank"
                                ><i class="ti-eye text-info m-r-10"></i>
                              </a>
                              <a
                                class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                                (click)="changeImage(i)"
                                placement="top"
                                ngbTooltip="Change Image"
                              >
                                <i class="ti-move text-danger m-r-10"></i>
                              </a>
                            </div>
                        </div>
                        </div>
                        <div
                          class="form-group m-xl-0 col-12 col-xl-4 d-xl-flex align-items-center p-0"
                        >
                          <label class="col-12 col-xl-3">
                            <app-required-indication
                              [control]="item.get('name')"
                              [label]="'Affected area'"
                            >
                            </app-required-indication>
                          </label>
                          <div class="d-flex col-12 col-xl-9 p-0">
                            <div class="col-10">
                              <app-form-error-wrapper
                                [control]="item.get('name')"
                                [controlName]="'Title'"
                                [apiErrorType]="'name'"
                              >
                                <input
                                  type="text"
                                  name="title-{{ i }}"
                                  class="form-control"
                                  formControlName="name"
                                />
                              </app-form-error-wrapper>
                            </div>
                            <div class="col-2 text-right" *ngIf="i !== 0 && i !== 1">
                              <button
                                class="btn btn-danger"
                                (click)="removeDoc(i)"
                              >
                                <i class="mdi mdi-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group d-xl-flex align-items-center">
                    <label class="col-12 col-xl-1"></label>
                    <div class="col-12 col-xl-10 pl-5">
                      <button
                        type="button"
                        class="btn btn-primary primary-background"
                        (click)="addDoc()"
                      >
                        <i class="mdi mdi-plus"></i> Add More
                      </button>
                    </div>
                  </div>
                </div>
            </div>  
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button
              type="button"
              (click)="cancelClick()"
              class="btn btn-secondary mr-2"
            >
              Cancel
            </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="shieldForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Claim'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="shieldForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Claim'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>