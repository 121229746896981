<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="fl-lt ">Claim Preview</div>
        <div class="card-actions">
          <button class="btn btn-white" routerLink="/lease-list">
            <i class="mdi mdi-arrow-left mr-1"></i> <span>Back</span>
          </button>
          <!-- <button
            class="btn btn-white"
            *ngIf="
              (claims.status === 'in progress' ||
                ((loggedInUserType === 'superadmin' ||
                  loggedInUserType === 'dowcsuperadmin') &&
                  claims.status === 'rejected') ||
                ((loggedInUserType === 'superadmin' ||
                  loggedInUserType === 'dowcsuperadmin') &&
                  claims.status !== 'approved')) &&
              claims.currentStep > 3
            "
            (click)="downloadDocuments(claims._id)"
            placement="top"
          >
            <i class="ti-download text-info m-r-10"></i><span>Download</span>
          </button> -->

          <!-- <button
            class="btn btn-white"
            *ngIf="
              claims.status === 'in progress' ||
              (rejectEdit === 'allow' && claims.status !== 'approved') ||
              ((loggedInUserType === 'superadmin' ||
                loggedInUserType === 'dowcsuperadmin') &&
                claims.status !== 'approved' &&
                claims.status !== 'rejected')
            "
            routerLink="/claim/edit/{{ claims._id }}/{{ claims.currentStep }}"
            [queryParams]="{ page: claims.currentStep }"
            placement="top"
            ngbTooltip="Update Claim"
          >
            <i class="ti-pencil text-info m-r-10"></i><span>Edit</span>
          </button> -->
        </div>
      </div>
      
      <div class="card-group"  *ngIf="claimDomain">
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-md-12 text-center p-1 domain-bg">
              <img class="domain-logo" style="width: 150px;" src={{claimDomain.appLogo}} alt={{claimDomain.appName}}> 
            </div>      
          </div>
        </div>
      </div>

      <div class="card-group" *ngIf="claims">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Claim Details</td>
                  </tr>
                  <tr>
                    <td width="60%">
                      <span>Claim No</span>
                    </td>
                    <td width="40%">
                      {{ claims.sequence }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Claim Status</span>
                    </td>
                    <td>
                      <span class="label text-white" [ngClass]="{
                        'bg-inprogress': claims.status === 'in progress',
                        'bg-inreview': claims.status === 'in review',
                        'bg-approved': claims.status === 'approved',
                        'bg-completed': claims.status === 'completed',
                        'bg-rejected': claims.status === 'rejected'
                      }">{{ claims.status | titlecase }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created By</span>:
                    </td>
                    <td>
                      <div *ngIf="claims?.userId?.firstName; else cCustomer">
                        {{ claims.userId?.firstName }} {{ claims.userId?.lastName }}
                      </div>
                      <ng-template #cCustomer>
                        {{ appname }} User
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created On</span>
                    </td>
                    <td>
                      {{ claims.createdAt | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} By</span>
                    </td>
                    <td>
                      <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                        {{ claims.adminId?.firstName }}
                        {{ claims.adminId?.lastName }}
                      </span>
                      <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                    </td>

                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} On</span>
                    </td>
                    <td>
                      {{ claims.updatedAt
                      | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'rejected'" class="alert alert-danger">
                    <td colspan="2">
                      What is reason behind reject claim ?<br />
                      {{ claims.statusMessage }}
                    </td>
                  <tr *ngIf="
                    (loggedInUserType === 'superadmin' ||
                      loggedInUserType === 'dowcsuperadmin') &&
                    claims.status !== 'in progress'
                  ">
                    <td colspan="2">
                      <div class="form-group mb-0 status-change">
                        <form [formGroup]="statusChange">
                          <label class="d-block">Update Status</label>
                          <select class="form-control custom-select m-r-10" name="status" formControlName="status">
                            <option class="custom-select-option" value="">Select Status</option>
                            <option class="custom-select-option" *ngIf="
                              claims.status !== 'in progress'" value="in progress">In Progress</option>
                            <option *ngIf="claims.status !== 'in review' && claims.status !== 'in progress'"
                              class="custom-select-option" value="in review">In Review</option>
                          </select>
                          <button (click)="changeStatus(claims._id)" class="btn btn-primary d-flex align-items-center">
                            Update
                          </button>
                        </form>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Customer Information</td>
                  </tr>
                  <tr>
                    <td width="60%">Contract Number</td>
                    <td width="40%">{{ claims.contractNumber }}</td>
                  </tr>
                  <tr>
                    <td>Date Leased Vehicle Returned</td>
                    <td>{{ claims.dateOfLoss_at | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Purchased</td>
                    <td>
                      {{ claims.vehiclePurchased === 'N' ? 'New' : 'Used' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{{ claims.customerName }}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.streetAddress }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.city">{{ claims.city }} - {{ claims.state }} - {{ claims.zipCode }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.phoneNumber > 8">
                        {{ claims.phoneNumber | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ claims.email }}</td>
                  </tr>
                  <tr>
                    <td>Dealer</td>
                    <td *ngIf="claims.dealer">{{ claims?.dealer?.name }}</td>
                    <td *ngIf="!claims.dealer"></td>
                  </tr>
                  <tr>
                    <td>Amount Approved</td>
                    <td>
                      {{ claims.amountApproved | currency: 'USD':'symbol':'1.2-2'}}
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">

                <tbody>
                  <tr>
                    <td colspan="2" class="table-title">Dealership Information</td>
                  </tr>
                  <tr>
                    <td width="60%">Contact Name at Dealership</td>
                    <td width="40%">
                      {{ claims.dealershipConatactAtName }}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Email Dealership</td>
                    <td>{{ claims.dealershipEmail }}</td>
                  </tr>
                  <tr>
                    <td>Dealership Phone Number</td>
                    <td>{{ claims.dealershipPhoneNumber }}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.dealershipAddress }}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{{ claims.dealershipCity }}</td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>{{ claims.dealershipState }}</td>
                  </tr>
                  <tr>
                    <td>Zip Code</td>
                    <td>{{ claims.dealershipZipCode }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Vehicle Information</td>
                  </tr>
                  <tr>
                    <td width="60%">VIN Number</td>
                    <td width="40%">{{ claims.vinNumber }}</td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>{{ claims.year }}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td>{{ claims.make }}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td>{{ claims.model }}</td>
                  </tr>
                  <tr>
                    <td>Current mileage</td>
                    <td>{{ claims.currentMileage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-8">
              <table class="w-100 preview-table">
                <tbody>
                  <tr>
                    <td class="table-title" colspan="2">
                      Customer Documents Needed
                    </td>
                  </tr>
                  <tr>
                    <td>Did you return the vehicle to your selling dealer?</td>
                    <td>{{ claims.isVehicleReturn ? 'Yes' : 'No' }}</td>
                  </tr>
                  <tr>
                    <td>Has your vehicle been inspected by the leasing company prior to turning it in?</td>
                    <td>
                      {{ claims.isVehicleInspected ? 'Yes' : 'No' }}
                    </td>
                  </tr>
                  <tr>
                    <td>What date does your lease expire?</td>
                    <td>{{ claims.leaseExpireDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>
                      Uploaded your damage report
                    </td>
                    <td>
                      <a class="doc" *ngIf="claims.damangeReport" href="{{ baseUrl }}{{ claims.damangeReport }}"
                        target="_blank">{{ claims.damangeReport.replace('lease/','').split('/')[1].replaceAll('_','
                        ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      upload your Lease Wear & Tear contract
                    </td>
                    <td>
                      <a class="doc" *ngIf="claims.contractDoc" href="{{ baseUrl }}{{
                          claims.contractDoc
                        }}" routerLinkActive="active" target="_blank">{{
                        claims.contractDoc.replace('lease/','').split('/')[1].replaceAll('_',' ').substring(14)
                        }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>upload your original lease agreement</td>
                    <td>
                      <a class="doc" *ngIf="claims.leaseAgreement" href="{{ baseUrl }}{{ claims.leaseAgreement }}"
                        routerLinkActive="active" target="_blank">{{
                        claims.leaseAgreement.replace('lease/','').split('/')[1].replaceAll('_',' ').substring(14)
                        }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      upload the odometer statement/receipt you received when returning your vehicle to your selling
                      dealer
                    </td>
                    <td>
                      <a class="doc" *ngIf="claims.odometerStatement" href="{{ baseUrl }}{{ claims.odometerStatement }}"
                        routerLinkActive="active" target="_blank">{{
                        claims.odometerStatement.replace('lease/','').split('/')[1].replaceAll('_',' ').substring(14)
                        }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      upload any photos of physical damage to your vehicle
                    </td>
                    <td>
                      <a class="doc" *ngIf="claims.physicalDamage" href="{{ baseUrl }}{{ claims.physicalDamage }}"
                        routerLinkActive="active" target="_blank">{{
                        claims.physicalDamage.replace('lease/','').split('/')[1].replaceAll('_',' ').substring(14)
                        }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Uploaded any additional documents either requested by the administrator or that you would like to
                      provide
                    </td>
                    <td>
                      <a class="doc" *ngIf="claims.additionalDocuments"
                        href="{{ baseUrl }}{{ claims.additionalDocuments }}" routerLinkActive="active"
                        target="_blank">{{
                        claims.additionalDocuments.replace('lease/','').split('/')[1].replaceAll('_',' ').substring(14)
                        }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" *ngIf="loggedInUserType === 'dowcsuperadmin'">
            <div class="col-12">
              <table width="100%">
                <thead>
                  <tr>
                    <th colspan="3" class="table-title">Audit logs</th>
                  </tr>
                  <tr>
                    <th width="33.34%">Process</th>
                    <th width="33.33%">Updated At</th>
                    <th width="33.33%">Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                          let log of history">
                    <td class="text-capitalize">{{
                      log.type.replace('leaseclaim','').replace('lease','').replace('create','created').replace('update','updated').replaceAll('-','
                      ') }}</td>
                    <td>{{ log.updateTime | date: 'MM/dd/yyyy hh:mm:ss' }}</td>
                    <td>{{ log.cid?.firstName}} {{ log.cid?.lastName}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-no-data-msg [message]="'No Data Found'" *ngIf="claims && claims.length === 0 && !isLoading">
        </app-no-data-msg>
        <app-table-loader [isLoading]="isLoading"></app-table-loader>
      </div>
      <!-- Old view -->
      <!-- <div
        *ngIf="
          (loggedInUserType === 'superadmin' ||
            loggedInUserType === 'dowcsuperadmin') && claims.status !== 'in progress'
        "
        class="status-chnage-container card-body"
      >
        <div class="form-group mb-0 status-change">
          <form [formGroup]="statusChange">
            <label class="d-block">Update Status</label>
            <select
              class="form-control custom-select m-r-10"
              name="status"
              formControlName="status"
            >
              <option class="custom-select-option" value=""
                >Select Status</option
              >
              <option class="custom-select-option" *ngIf="
              claims.status !== 'in progress'" value="in progress"
                >In Progress</option
              >
              <option
                *ngIf="claims.status !== 'in review' && claims.status !== 'in progress'"
                class="custom-select-option"
                value="in review"
                >In Review</option
              >
            </select>
            <button
              (click)="changeStatus(claims._id)"
              class="btn btn-primary d-flex align-items-center"
            >
              Update
            </button>
          </form>
        </div>
      </div>
      <div class="card-body pr-0 pl-0">
        <div class="table-responsive">
          <table class="table table-striped mb-table">
            <thead>
              <tr>
                <th colspan="2">Claim Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>                  
                  <span style="padding-right: 74px;">Claim No</span>:
                  {{ claims.sequence }}                  
              </td>
                <td>
                  <span style="padding-right: 48px;">Claim Status</span>:
                  {{ claims.status | titlecase }}
                </td>
              </tr>
              <tr>
                <td>
                  <div *ngIf="claims?.userId?.firstName; else cCustomer">
                    <span style="padding-right: 60px;">Created By</span>:
                    {{ claims.userId?.firstName }} {{ claims.userId?.lastName }}
                  </div>
                  <ng-template #cCustomer>
                    <span style="padding-right: 60px;">Created By</span>:
                    {{ appname }} User
                  </ng-template>
                </td>
                <td>
                  <span style="padding-right: 57px;">Created On</span>:
                  {{ claims.createdAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr *ngIf="claims.status === 'completed'">
                <td>
                  <span style="padding-right: 34px;">{{ claims.status | titlecase }} By</span>:
                  <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                    {{ claims.adminId?.firstName }}
                    {{ claims.adminId?.lastName }}
                  </span>
                  <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                </td>
                <td>
                  <span style="padding-right: 32px;">{{ claims.status | titlecase }} On</span>:
                  {{ claims.updatedAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr *ngIf="
                  claims.status === 'approved' || claims.status === 'rejected'
                ">
                <td>
                  <span style="padding-right: 48px;">{{ claims.status | titlecase }} By</span>:
                  <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                    {{ claims.adminId?.firstName }}
                    {{ claims.adminId?.lastName }}
                  </span>
                  <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                </td>
                <td>
                  <span style="padding-right: 45px;">{{ claims.status | titlecase }} On</span>:
                  {{ claims.updatedAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="claims.status === 'rejected'" class="alert alert-danger">
          What is reason behind reject claim ?<br />
          {{ claims.statusMessage }}
        </div>
        <div class="table table-responsive smart-table table-striped" *ngIf="claims">
          <table class="w-100 preview-table">
            <tbody>
              <tr class="table-success">
                <td colspan="2">Customer Information</td>
              </tr>
              <tr>
                <td width="50%">Contract Number</td>
                <td width="50%">{{ claims.contractNumber }}</td>
              </tr>
              <tr>
                <td>Date Leased Vehicle Returned</td>
                <td>{{ claims.dateOfLoss_at | date: 'MM/dd/yyyy' }}</td>
              </tr>
              <tr>
                <td>Vehicle Purchased</td>
                <td>
                  {{ claims.vehiclePurchased === 'N' ? 'New' : 'Used' }}
                </td>
              </tr>
              <tr>
                <td>Customer Name</td>
                <td>{{ claims.customerName }}</td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.streetAddress }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.city }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.state }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.zipCode }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <span *ngIf="claims.phoneNumber > 8">
                    {{ claims.phoneNumber | phone }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ claims.email }}</td>
              </tr>
              <tr>
                <td>Dealer</td>
                <td *ngIf="claims.dealer">{{ claims?.dealer?.name }}</td>
                <td *ngIf="!claims.dealer"></td>
              </tr>
              <tr>
                <td>Amount Approved</td>
                <td>
                  {{
                    claims.amountApproved | currency: 'USD':'symbol':'1.2-2'
                  }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr class="table-success">
                <td colspan="2">Dealership Information</td>
              </tr>
              <tr>
                <td>Contact Name at Dealership</td>
                <td>
                  {{ claims.dealershipConatactAtName }}
                </td>
              </tr>
              <tr>
                <td>Contact Email Dealership</td>
                <td>{{ claims.dealershipEmail }}</td>
              </tr>
              <tr>
                <td>Dealership Phone Number</td>
                <td>{{ claims.dealershipPhoneNumber }}</td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.dealershipAddress }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.dealershipCity }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.dealershipState }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.dealershipZipCode }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr class="table-success">
                <td colspan="2">Vehicle Information</td>
              </tr>
              <tr>
                <td>VIN Number</td>
                <td>{{ claims.vinNumber }}</td>
              </tr>
              <tr>
                <td>Current mileage</td>
                <td>{{ claims.currentMileage }}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{{ claims.year }}</td>
              </tr>
              <tr>
                <td>Make</td>
                <td>{{ claims.make }}</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>{{ claims.model }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2" class="table-success">Customer Documents Needed</td>
              </tr>
              <tr>
                <td>Did you return the vehicle to your selling dealer?</td>
                <td>{{ claims.isVehicleReturn ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td>Has your vehicle been inspected by the leasing company prior to turning it in?</td>
                <td>
                  {{ claims.isVehicleInspected ? 'Yes' : 'No' }}
                </td>
              </tr>
              <tr>
                <td>What date does your lease expire?</td>
                <td>{{ claims.leaseExpireDate | date: 'MM/dd/yyyy' }}</td>
              </tr>
              <tr>
                <td>
                  Uploaded your damage report
                </td>
                <td>
                  <a class="doc" *ngIf="claims.damangeReport" href="{{ baseUrl }}{{ claims.damangeReport }}"
                    target="_blank">{{ claims.damangeReport.replace('lease/','').split('/')[1] }}</a>
                </td>
              </tr>
              <tr>
                <td>
                  upload your Lease Wear & Tear contract
                </td>
                <td>
                  <a class="doc" *ngIf="claims.contractDoc" href="{{ baseUrl }}{{
                      claims.contractDoc
                    }}" routerLinkActive="active" target="_blank">{{
                      claims.contractDoc.replace('lease/','').split('/')[1]
                    }}</a>
                </td>
              </tr>
              <tr>
                <td>upload your original lease agreement</td>
                <td>
                  <a class="doc" *ngIf="claims.leaseAgreement" href="{{ baseUrl }}{{ claims.leaseAgreement }}"
                    routerLinkActive="active" target="_blank">{{ claims.leaseAgreement.replace('lease/','').split('/')[1] }}</a>
                </td>
              </tr>
              <tr>
                <td>
                  upload the odometer statement/receipt you received when returning your vehicle to your selling dealer
                </td>
                <td>
                  <a class="doc" *ngIf="claims.odometerStatement" href="{{ baseUrl }}{{ claims.odometerStatement }}"
                    routerLinkActive="active" target="_blank">{{ claims.odometerStatement.replace('lease/','').split('/')[1] }}</a>
                </td>
              </tr>
              <tr>
                <td>
                  upload any photos of physical damage to your vehicle
                </td>
                <td>
                  <a class="doc" *ngIf="claims.physicalDamage" href="{{ baseUrl }}{{ claims.physicalDamage }}"
                    routerLinkActive="active" target="_blank">{{ claims.physicalDamage.replace('lease/','').split('/')[1] }}</a>
                </td>
              </tr>
              <tr>
                <td>
                  Uploaded any additional documents either requested by the administrator or that you would like to
                  provide
                </td>
                <td>
                  <a class="doc" *ngIf="claims.additionalDocuments" href="{{ baseUrl }}{{ claims.additionalDocuments }}"
                    routerLinkActive="active" target="_blank">{{ claims.additionalDocuments.replace('lease/','').split('/')[1] }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        
      </div> -->
      <div class="card-footer">
        <div class="step-btn-container d-flex align-items-center justify-content-between pull-right">
          <button type="button" class="btn btn-primary d-flex align-items-center" routerLink="/lease-list">
            <i class="mdi mdi-arrow-left mr-1"></i>
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>