import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var debitcancelService = /** @class */ (function () {
    function debitcancelService(httpClient) {
        this.httpClient = httpClient;
        this.claimData = null;
        this.claimData$ = new EventEmitter();
    }
    debitcancelService.prototype.add = function (payload) {
        return this.httpClient.post("/debitcanceladd", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.update = function (claimId, payload) {
        return this.httpClient.post("/debitcancelupdate/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.claimDate_from
            ? params.append('claimDate_from', "" + payload.claimDate_from)
            : params;
        params = payload.claimDate_to
            ? params.append('claimDate_to', "" + payload.claimDate_to)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.debitorName
            ? params.append('debitorName', "" + payload.debitorName)
            : params;
        params = payload.debitorEmail
            ? params.append('debitorEmail', "" + payload.debitorEmail)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/debitcancelclaims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getById = function (claimId) {
        return this.httpClient.get("/debitcancel/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.acceptRequest = function (claimId, payload) {
        return this.httpClient.put("/debitcancel/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.rejectRequest = function (claimId, payload) {
        return this.httpClient.put("/debitcancel/" + claimId + "/rejectrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.changeStatus = function (claimId, payload) {
        return this.httpClient.put("/debitcancel/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.deleteClaim = function (claimId) {
        return this.httpClient.delete("/debitcancel/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getAnalytics = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/debitcancelanalytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.ngInjectableDef = i0.defineInjectable({ factory: function debitcancelService_Factory() { return new debitcancelService(i0.inject(i1.HttpClient)); }, token: debitcancelService, providedIn: "root" });
    return debitcancelService;
}());
export { debitcancelService };
