export const environment = {
  env: 'PROD',
  production: true,
  // live url
  // serverBaseUrl: 'https://claim-api.dowc.com',
  // serverUrl: 'https://claim-api.dowc.com/v1',
  // domainUrl: 'https://claims.dowc.com/',
  // s3Url: 'https://dowc-gap-claims.s3.amazonaws.com/',
  // adminSystemURL: "https://myvehicleadmin-api-live.dowc.com/v1/",
  // sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
  // siteKey:"6LdnHzMcAAAAAGvK778g8xYFLNlekFZAQdEDzl8l",
  // name:'APS',
  // logo:'aps',

  // qa url
  serverBaseUrl: 'https://qaclaim-api.dowc.com',
  serverUrl: 'https://qaclaim-api.dowc.com/v1',
  domainUrl: 'http://qaclaims.dowc.com/',
  s3Url: 'https://gap-claim.s3.amazonaws.com/',
  adminSystemURL: "https://api-alpha.myvehicleadmin.com/v1/",
  sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
  siteKey:"6Lc_PT4cAAAAABMKyj-dH5cU9cYBS0M8mq9JcBnT",
  name:'APS',
  logo:'APS',

  // local url
  // serverBaseUrl: 'http://localhost:1400',
  // serverUrl: 'http://localhost:1400/v1',
  // domainUrl: 'http://localhost:4404/',
  // s3Url: 'https://gap-claim.s3.amazonaws.com/',
  // adminSystemURL: 'https://api-alpha.myvehicleadmin.com/v1/',
  // sharepointAPI:"http://localhost/sharepoint_api/",
  // siteKey: '6Ld-igwcAAAAAHCMyfLXQIVwxrbytQI_udVVb4Rv',
  // name: 'APS',
  // logo: 'APS',

  // qa url
  // serverBaseUrl: 'https://qaclaim-api.dowc.com',
  // serverUrl: 'https://qaclaim-api.dowc.com/v1',
  // domainUrl: 'http://qaclaims.dowc.com/',
  // s3Url: 'https://gap-claim.s3.amazonaws.com/',
  // adminSystemURL: "https://myvehicleadmin-api-beta.dowc.com/v1/",
  // sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
  // siteKey:"6Lc_PT4cAAAAABMKyj-dH5cU9cYBS0M8mq9JcBnT",
  // name: 'Optimus Warranty',
  // logo: 'Optimus Warranty',

  // qa1 url
  // serverBaseUrl: 'https://uatclaimsapi.dowc.com',
  // serverUrl: 'https://uatclaimsapi.dowc.com/v1',
  // domainUrl: 'https://uatclaimsfront.dowc.com/',
  // s3Url: 'https://gap-claim.s3.amazonaws.com/',
  // adminSystemURL: "https://myvehicleadmin-api-beta.dowc.com/v1/",
  // sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
  // siteKey:"6Lc_PT4cAAAAABMKyj-dH5cU9cYBS0M8mq9JcBnT",
  // name: 'Optimus Warranty',
  // logo: 'Optimus Warranty',

  // APS url
  // serverBaseUrl: 'https://dowcapi.apsclaim.com',
  // serverUrl: 'https://dowcapi.apsclaim.com/v1',
  // domainUrl: 'https://apsclaim.com/',
  // s3Url: 'https://apsclaim.s3.amazonaws.com/',
  // name:'APS',
  // logo:'aps',
  /* domainUrl: 'http://qaclaims.dowc.com/', */
  // name:'DOWC',
  // logo:'dowc',
  // local url
  // serverBaseUrl: 'https://claim-api.dowc.com',
  // serverUrl: 'https://claim-api.dowc.com/v1',
  // domainUrl: 'http://localhost:4404/',
  // s3Url: 'https://gap-claim.s3.amazonaws.com/',
  // name:'DOWC',
  // logo:'dowc',

  /* domainUrl: 'http://qaclaims.dowc.com/', */
};
