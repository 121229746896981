import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectClaimComponent } from '@app/shared/modals/reject-claim/reject-claim.component';
import { Subject, from, Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClaimService } from '@app/core/service/claim.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { DateTime } from 'luxon';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CustomerService } from '@app/core/service/customer.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';

@Component({
  selector: 'app-dashboard-claim-list',
  templateUrl: './dashboard-claim-list.component.html',
  styleUrls: ['./dashboard-claim-list.component.scss']
})
export class DashboardClaimListComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  loggedInUserType: any = null;
  loggedInUserdealer:any=null;
  user: Authentication.User;
  private unsubscribe: Subject<void> = new Subject();
  selectedCustomer: any = null;
  isLoading = false;
  claims: any = [];
  fiterForm: FormGroup;
  environmentUrl = '';
  sortBy = 'createdAt';
  sortDir = -1;
  perPage = 10;
  currentPage = 1;
  dealersList: any;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  timer:any;
  hostname = window.location.hostname;
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 2
          ? []
          : this.dealersList
            .filter(
              (v: { name: string; }) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
      )
    );
  formatter = (x: { name: string }) => x.name;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authenticationService: AuthenticationService,
    private claimService: ClaimService,
    private formBuilder: FormBuilder,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService
  ) {
    this.environmentUrl = environment.serverUrl;
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.loggedInUserdealer=this.user.dealerId;
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.loggedInUserdealer=this.user.dealerId;
      });
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    // if (!this.selectedCustomer && this.loggedInUserType === 'admin') {
    //   this.router.navigate(['/customer'], {
    //     replaceUrl: true
    //   });
    // }
    this.createForm();
    this.getClaimsByFilter('report');
    this.route.queryParams.pipe().subscribe(params => {
      if (params && params.page) {
        if (!isNaN(params.page)) {
          this.currentPage = +params.page;
        }
      }
    });
  }
  ngOnInit() { 
    if (this.loggedInUserType === 'customer') {
      this.router.navigate(['/products']);
    }
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  createForm() {
    let startdate = new Date();
    let todate = new Date();
    this.fiterForm = this.formBuilder.group({
      sequence: ['', Validators.compose([Validators.maxLength(5)])],
      addendumNumber: ['', Validators.compose([Validators.maxLength(5)])],
      // date_from: [{ year: 2018, month: 1, day: 1 }],
      // date_to: [{ year: todate.getFullYear(), month: todate.getMonth() + 1, day: todate.getDate() }],
      customerName: [''],
      email: [''],
      status: [(this.user.userRoleId.slug === 'dowcadmin' || this.user.userRoleId.slug === 'admin' || this.user.userRoleId.slug === 'customer') ? '' : 'inarchive'],
      sortFieldFilter: ['createdAt'],
      sortByFilter: ['-1'],
      dealer: [''],
      perPage: 10
    });

    this.fiterForm.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      // this.getClaimsByFilter('report');
      const self = this;
      clearInterval(self.timer);
      self.timer = setInterval(() => {
          clearInterval(self.timer);
          this.getClaimsByFilter('report');
      }, 500);
    });
    this.customerService
      .getDealers()
      /* .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ) */
      .subscribe((response: any) => {
        this.dealersList = response.records;
      });
  }

  getClaim() { // not used
    this.isLoading = true;
    this.claimService
      .getClaims()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          this.claims = response.records;
        }
      });
  }


  exportReport() {
    this.getClaimsByFilter('export');
  }

  getClaimsByFilter(mode:string) {
    let payload = this.fiterForm.value;   
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      payload.dateOfLoss_at_from = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }

    if (payload.date_to) {
      payload.dateOfLoss_at_to = DateTime.fromObject(
        payload.date_to
      ).toISO();
    }
    if (typeof payload.dealer == "object") {
      payload.dealer = payload.dealer._id;
    } else if (typeof payload.dealer == "string" && payload.dealer !== "" && this.currentPage === this.pager.currentPage) {
      return false;
    }

    payload.mode = mode;
    payload.currentPage = this.pager.currentPage;
    this.pager.perPage = payload.perPage;
    this.isLoading = true;
    this.claimService
      .getClaimsByFilter(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (mode === 'export') {
          this.downLoadReport(response.data);
          this.toastrService.info('File has been downloaded.');
          this.isLoading = false;
        } else {
        this.claims = response.records;
        this.renderPagination(response.page, response.total, response.totalPage);
        }        
        this.utilService.updateDomainCss();
      });
  }

  acceptRequest(claim: any) {
    const payload = {
      message: `Hi, ${claim.customerName} your deal has been accepted for claim`,
      adminId: this.user._id
    };
    this.claimService
      .acceptRequest(claim._id, payload)
      .subscribe((response: any) => {
        if (response) {
          this.toastrService.info('Claim accepted Successfully!..');
          this.getClaimsByFilter('report');
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }

  async openRejectPopup(claimId: string, type:any) {
    const modalRef = await this.modalService.open(RejectClaimComponent);
    modalRef.componentInstance.claimId = claimId;
    modalRef.componentInstance.adminId = this.user._id;
    modalRef.componentInstance.product = 'gap';
    modalRef.componentInstance.rejectType = type;

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.getClaimsByFilter('report');
    }
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  async openDeleteClaim(claimId: string) {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'claims.deleteClaim.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'claims.deleteClaim.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'claims.deleteClaim.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'claims.deleteClaim.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'claims.deleteClaim.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'claims.deleteClaim.method',
      CONFIRMATION_MSGS
    );

    modalRef.componentInstance.payload = [claimId];

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.toastrService.info('Claim deleted Successfully!..');
      this.getClaimsByFilter('report');
    }
  }

  async downloadDocuments(claimId: string) {
    this.isLoading = true;
    this.claimService.downloadDocuments(claimId).subscribe(
      respData => {
        this.downLoadFile(respData.records);
        this.toastrService.info('File has been downloaded.');
        this.isLoading = false;
      },
      error => {
        //console.log(error);
        this.toastrService.warning('Sorry! Documents not found.');
        this.isLoading = false;
      }
    );
  }

  downLoadFile(data: string) {
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.style.display = 'none';
    const url = this.environmentUrl.slice(0, -3) + '/downloads/' + data;
    aTag.href = url;
    aTag.setAttribute('target', '_blank');
    /* aTag.setAttribute('download', claimId + ".zip");
    if (typeof aTag.download === 'undefined') {
      aTag.setAttribute('target', '_blank');
    } */
    aTag.click();
    document.body.removeChild(aTag);
  }

  downLoadReport(data: string) {
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.style.display = 'none';
    const url = this.environmentUrl.slice(0, -3) + '/' + data;
    aTag.href = url;
    aTag.setAttribute('target', '_blank');
    /* aTag.setAttribute('download', claimId + ".zip");
    if (typeof aTag.download === 'undefined') {
      aTag.setAttribute('target', '_blank');
    } */
    aTag.click();
    document.body.removeChild(aTag);
  }

  async sort(sortByVal: string) {
    if (this.sortBy === sortByVal) {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
    } else {
      this.sortDir = 1;
    }
    /*  this.sortFieldFilter.setValue(sort);
    this.sortByFilter.setValue(sortDir); */
    this.fiterForm.value.sortFieldFilter = sortByVal;
    this.fiterForm.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getClaimsByFilter('report');
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages  && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getClaimsByFilter('report');
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.currentPage=page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  checkAdmindealer(dealerId:any){
    let adminDealer = false;
    if (this.user.isGroupDealer) {
      var group: any = this.user.dealerGroupId;
      for (let grp of group.dealers) {
        if (grp.dealerId == dealerId) {
          adminDealer = true
        }
      }
    } else if (dealerId == this.loggedInUserdealer) {
      adminDealer = true
    }
    return adminDealer;
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
