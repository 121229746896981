import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClaimService } from '@app/core/service/claim.service';
import { KeyfechService } from '@app/core/service/keyfetch.service';
import { shieldService } from '@app/core/service/shield.service';
import { debitcancelService } from '@app/core/service/debitcancel.service';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_VAR } from './../../../core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reject-claim',
  templateUrl: './reject-claim.component.html',
  styleUrls: ['./reject-claim.component.scss']
})
export class RejectClaimComponent implements OnInit {
  @Input()
  claimId?= '';
  adminId?= '';
  product?= '';
  amountApproved?= '';
  rejectType?= 'reject';

  selectedCustomer: any = null;
  rejectForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public claimService: ClaimService,
    public KeyfechService: KeyfechService,
    public shieldService: shieldService,
    public debitcancelService: debitcancelService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.createForm();
  }

  ngOnInit() {
    this.rejectForm.controls['amountApproved'].setValue(this.amountApproved);
  }

  createForm() {
    this.rejectForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      amountApproved: ['', [Validators.required]],
      adminId: [''],
      rejectEdit: [true]
    });
  }

  rejectRequest() {
    this.rejectForm.get('adminId').setValue(this.adminId);
     var formValue=this.rejectForm.value;
     if (this.product === 'shield') {
      this.shieldService
        .rejectRequest(this.claimId, formValue)
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'lease') {
      this.claimService
        .rejectLeaseRequest(this.claimId, formValue)
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'keyfetch') {
      this.KeyfechService
        .rejectRequest(this.claimId, formValue)
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if(this.product === 'debitcancel'){
      //if (formValue.amountApproved)
        delete formValue.amountApproved;
     this.debitcancelService
       .rejectRequest(this.claimId, formValue)
       .subscribe((response: any) => {
         if (response) {
           this.activeModal.close(response);
         } else {
           this.toastrService.warning('something went Wrong');
         }
       });
   } else {
       //if (formValue.amountApproved)
       console.log(this.rejectType);
         delete formValue.amountApproved;
         if(this.rejectType =='denied'){
          formValue.rejectEdit = false;
          this.claimService
          .denieRequest(this.claimId, formValue)
          .subscribe((response: any) => {
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          });
         } else {
          this.claimService
          .rejectRequest(this.claimId, formValue)
          .subscribe((response: any) => {
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          });
         }
     
    }


  }
}
