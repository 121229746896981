import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ClaimService } from '@app/core/service/claim.service';
import { UtilService, LOCAL_VAR } from '@app/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '@app/core/service/customer.service';
import {
  map,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { DateTime } from 'luxon';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})


export class AnalyticsComponent implements OnInit {
  loggedInUserType: any = null;
  user: Authentication.User;
  fiterForm: FormGroup;
  helplist: any = [];
  activities: any = [];
  dealersList: any = [];
  dealerClaims: any = [];
  GapAmount: any = [];
  dealer = '';
  dealername = '';
  isLoading = false;

  totalClaims = 0;
  totalCompleted = 0;
  totalaceeped = 0;
  gapAmount = 0;
  customers = {
    new: 0,
    activated: 0,
    inactivated: 0,
    deleted: 0
  }
  fromdate: any;
  todate: any;
  days = 30;
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 2
          ? []
          : this.dealersList
            .filter(
              (v: { name: string; }) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
      )
    );
  formatter = (x: { name: string }) => x.name;

  //All status
  allclaimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
  allclaimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };

   //status
   claimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
   claimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };

   //priod claims
   priodclaimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
   priodclaimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };

  // lineChart
  lineChartData: Array<any> = [{ data: [], label: 'New Claims' }, { data: [], label: 'Approved Claims' }, { data: [], label: 'Rejected Claims' }];
  lineChartLabels: Array<any>;
  lineChartOptions: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            color: 'rgba(120, 130, 140, 0.13)'
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(120, 130, 140, 0.13)'
          }
        }
      ]
    },
    lineTension: 10,
    responsive: true,
    maintainAspectRatio: false
  };
  lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: 'rgba(25,118,210,0.0)',
      borderColor: 'rgba(25,118,210,1)',
      pointBackgroundColor: 'rgba(25,118,210,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(25,118,210,0.5)'
    },
    {
      // dark grey
      backgroundColor: 'rgba(38,218,210,0.0)',
      borderColor: 'rgba(38,218,210,1)',
      pointBackgroundColor: 'rgba(38,218,210,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(38,218,210,0.5)'
    }, {
      // dark red
      backgroundColor: '#fff',
      borderColor: '#ef5350',
      pointBackgroundColor: 'rgba(229, 35, 35, 0.52)',
      pointBorderColor: '#fb3a3a',
      pointHoverBackgroundColor: '#fb3a3a',
      pointHoverBorderColor: 'rgba(38,218,210,0.5)'
    }
  ];

  lineChartColors1 = [
    {
      backgroundColor: '#A4BB39'
    },
    {
      backgroundColor: '#009B4C'
    },
    {
      backgroundColor: '#B75A50'
    }
  ]

  lineChartType = 'line';

  // Sales Analytics Pie chart
  public pieChartLabels: string[] = ["In Progress", "In Review", "Completed", "Rejected", "Approved"];
  public pieChartData: number[] = [];
  public pieChartType = 'pie';
  public pieChartOptions ={
    responsive: true,
    maintainAspectRatio: false
  };

  // Barchart
  // ADD CHART OPTIONS. 
  barchartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    responsive: true,
    maintainAspectRatio: false
  }

  barchartlabels: any = [];

  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  barchartData: any = [
    {
      label: 'GAP Amount',
      data: []
    }
  ];

  // CHART COLOR.
  barchartcolors = [
    {
      backgroundColor: 'rgba(30, 169, 224, 0.8)'
    }
  ]

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public utilService: UtilService,
    public datepipe: DatePipe,
    private ClaimService: ClaimService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
  }

  ngOnInit() {
    if(this.loggedInUserType === 'admin' || this.loggedInUserType === 'customer'){
      this.router.navigate(['/products']);
    }

    let startdate = new Date();
    let todate = new Date();
    this.todate = todate;
    this.fromdate = startdate.setDate(startdate.getDate() - this.days);
    this.fiterForm = this.formBuilder.group({
      date_from: [{ year: startdate.getFullYear(), month: startdate.getMonth() + 1, day: startdate.getDate() }],
      date_to: [{ year: todate.getFullYear(), month: todate.getMonth() + 1, day: todate.getDate() }],
      dealer: ['']
    });

    this.fiterForm.valueChanges.subscribe(values => {
      this.selectFilter();
    });

    /* if (this.user.dealerId != 'undefined' && this.loggedInUserType == 'admin') {
       this.fiterForm.controls.dealer.setValue(this.user.dealerId);
     }*/
    let dealersId = (this.user.dealerId) ? this.user.dealerId : '';
    this.customerService
      .getDealers()
      .subscribe((response: any) => {
        if (this.loggedInUserType !== 'dowcsuperadmin' && this.loggedInUserType !== 'dowcadmin' && !this.user.dealerGroupId) {
          let dealername = '';
          $.each(response.records, function (index, val) {
            if (dealersId === val._id) {
              dealername = val.name;
              return false;
            }
          });
          this.dealername = dealername;
        } else if(this.user.dealerGroupId) {
          this.customerService
          .getDealerGroupDetail(this.user.dealerGroupId)
          .subscribe((res: any) => {
            if (res.records) {
              let dealersgroup:any = [];
              $.each(res.records.dealers, function (index, val) {
                $.each(response.records, function (index, val1) {
                  if (val.dealerId.toString() === val1._id.toString()) {
                    dealersgroup.push(val1);
                    return false;
                  }
                });
              });
              this.dealersList = dealersgroup;
            } 
          });
        } else {
          this.dealersList = response.records;
        }
      });

    this.createForm();

  }

  createForm() {

    this.claimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.claimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.allclaimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.allclaimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.priodclaimStatus = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.priodclaimStatusBar = { 'total': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'completed': 0 };
    this.pieChartData = [0, 0, 0, 0, 0];

    let dates = [];
    let date = new Date(this.fromdate);
    date.setDate(date.getDate());
    var diff = Math.abs(new Date(this.todate).getTime() - new Date(this.fromdate).getTime());
    this.days = Math.ceil(diff / (1000 * 3600 * 24));
    for (let i = 0; i <= this.days; i++) {
      let d = new Date(date);
      dates.push(this.datepipe.transform(d, 'yyyy-MM-dd'));
      date.setDate(date.getDate() + 1);
    }
    this.lineChartLabels = dates;
    this.barchartlabels = dates;

    let payload: any;
    payload = { from: new Date(this.fromdate), to: new Date(this.todate), dealer: this.dealer };
    this.isLoading = true;
    this.ClaimService
      .getAllClaimStatus(payload)
      .subscribe((response: any) => {
        this.isLoading = false;
        this.renderRecords(response.data);
      });
  }

  selectFilter() {
    let payload = this.fiterForm.value;
    this.dealer = '';
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      this.fromdate = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }

    if (payload.date_to) {
      this.todate = DateTime.fromObject(
        payload.date_to
      ).toISO();
    }
    if (typeof payload.dealer == "object") {
      this.dealer = payload.dealer._id;
    } else if (typeof payload.dealer == "string" && payload.dealer !== "") {
      return false;
    }
    this.createForm();
  }

  renderRecords(data: any) {

    // Build status bar
    let allstatus = data.totalstatus;

    let allclaimStatus = this.allclaimStatus;
    let allclaimStatusBar = this.allclaimStatusBar;
    let ttotal = 0;
    allstatus.forEach(function (val: any) {
      let count = val.count;
      ttotal = ttotal + count;
      switch (val._id) {
        case 'completed':
          allclaimStatus.completed = count;
          break;
        case 'in review':
          allclaimStatus.inreview = count;
          break;
        case 'rejected':
          allclaimStatus.rejected = count;
          break;
        case 'in progress':
          allclaimStatus.inprogress = count;
          break;
        case 'approved':
          allclaimStatus.approved = count;
          break;
      }
    });
    allclaimStatus.total = ttotal;
    //Update Status Bar
    $.each(allclaimStatus, function (index, value) {
      allclaimStatusBar[index] = ((value * 100) / ttotal);
    });

    // Build status bar
    let peroidstatus = data.priodstatus;

    let peroidclaimStatus = this.priodclaimStatus;
    let peroidclaimStatusBar = this.priodclaimStatusBar;
    let ptotal = 0;
    peroidstatus.forEach(function (val: any) {
      let count = val.count;
      ptotal = ptotal + count;
      switch (val._id) {
        case 'completed':
          peroidclaimStatus.completed = count;
          break;
        case 'in review':
          peroidclaimStatus.inreview = count;
          break;
        case 'rejected':
          peroidclaimStatus.rejected = count;
          break;
        case 'in progress':
          peroidclaimStatus.inprogress = count;
          break;
        case 'approved':
          peroidclaimStatus.approved = count;
          break;
      }
    });
    peroidclaimStatus.total = ptotal;
    //Update Status Bar
    $.each(peroidclaimStatus, function (index, value) {
      peroidclaimStatusBar[index] = value?((value * 100) / ptotal):0;
    });

    // Build status bar
    let status = data.status;
    this.helplist = data.helplist;
    this.activities = data.auditlist;
    this.customers = data.customers;

    let claimStatus = this.claimStatus;
    let claimStatusBar = this.claimStatusBar;
    let total = 0;
    let completed = 0;
    let accepted = 0;
    status.forEach(function (val: any) {
      let count = val.count;
      total = total + count;
      switch (val._id) {
        case 'completed':
          completed = completed + count;
          claimStatus.completed = count;
          break;
        case 'in review':
          claimStatus.inreview = count;
          break;
        case 'rejected':
          claimStatus.rejected = count;
          break;
        case 'in progress':
          claimStatus.inprogress = count;
          break;
        case 'approved':
          accepted = accepted + count;
          claimStatus.approved = count;
          break;
      }
    });
    claimStatus.total = total;
    this.totalClaims = total;
    this.totalCompleted = completed;
    this.totalaceeped = accepted;
     //Update Status Bar
     $.each(claimStatus, function (index, value) {
      claimStatusBar[index] = ((value * 100) / total)?((value * 100) / total):0;
    });


    //GAP amount
    let totalGap: number = 0;

    //PieChart build
    let pieChartData: any = [];
    this.pieChartLabels.forEach(function (key, val: any) {
      let nodata = true;
      $.each(claimStatus, function (index, value) {
        if (key.toLocaleLowerCase().replace(' ','') == index.toLocaleLowerCase().replace(' ','')) {
          pieChartData.push(value);
          nodata = false;
          return true;
        }
      });
      if (nodata) {
        pieChartData.push(0);
      }
    });
    this.pieChartData = pieChartData;

    // Linechart build
    let created = data.created;
    let approved = data.approved;
    let rejected = data.rejected;
    let newClaims: Array<any> = [];
    let approvedClaims: Array<any> = [];
    let rejectedClaims: Array<any> = [];
    let approvedAmount: Array<any> = [];
    let totalcreated: number = 0;
    this.lineChartLabels.forEach(function (key, value: any) {
      //new
      let nonew = true;
      created.forEach(function (val: any) {
        if (val._id == key) {
          totalcreated = totalcreated + val.count;
          newClaims.push(val.count);
          nonew = false;
          return true;
        }
      });
      if (nonew) {
        newClaims.push(0);
      }
      //approved
      let noapp = true;
      approved.forEach(function (val: any) {
        if (val._id == key) {
          totalGap = totalGap + val.amount;
          approvedClaims.push(val.count.toFixed(2));
          approvedAmount.push(val.amount.toFixed(2));
          noapp = false;
          return true;
        }
      });
      if (noapp) {
        approvedClaims.push(0);
        approvedAmount.push(0);
      }

      //rejected
      let norejed = true;
      rejected.forEach(function (val: any) {
        if (val._id == key) {
          rejectedClaims.push(val.count);
          norejed = false;
          return true;
        }
      });
      if (norejed) {
        rejectedClaims.push(0);
      }
    });

    this.gapAmount = totalGap;

    this.lineChartData = [
      { data: newClaims, label: 'New Claims' },
      { data: approvedClaims, label: 'Approved' },
      { data: rejectedClaims, label: 'Rejected' }
    ];

    this.barchartData = [
      {
        label: 'GAP Settled Amount',
        data: approvedAmount
      }
    ];

       // dealer top 10 list
    if (this.loggedInUserType === 'dowcsuperadmin') {
      let claimdata: any = [];
      let dealersList: any = [];
      if (data.dealerClaim && data.dealerClaim.dealerList) {
        dealersList = data.dealerClaim.dealerList;
      } else {
        dealersList = this.dealersList;
      }
      if (data.dealerClaim) {
        $.each(data.dealerClaim.new, function (index, claim) {
          let value = { new: claim.count, dealer: {}, approved: 0, rejected: 0, amount: 0.00 };
          $.each(dealersList, function (index, dealer) {
            if (dealer._id == claim._id.dealer) {
              value.dealer = dealer;
              return false;
            }
          });
          data.dealerClaim.approved.forEach(function (val: any) {
            if (val._id.dealer === claim._id.dealer) {
              value.approved = val.count;
              value.amount = val.amount;
              return true;
            }
          });
          data.dealerClaim.rejected.forEach(function (val: any) {
            if (val._id.dealer === claim._id.dealer) {
              value.rejected = val.count;
              return true;
            }
          });
          claimdata.push(value);
        });
      }
      this.dealerClaims = claimdata;
      if (data.dealerClaim && data.dealerClaim.GapAmounts) {
        this.GapAmount = data.dealerClaim.GapAmounts;
      }
    }

  }

  ngAfterViewInit() {
    $('.list-task .list-group-item .checkbox label.custom-control').click(
      function () {
        $(this).toggleClass('task-done');
      }
    );
    this.utilService.updateDomainCss();
  }
}
