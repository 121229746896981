<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="keyfetchForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="keyfetchForm" novalidate>
        <div class="card-header">
          Key Protection Claim
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="row" *ngIf="loggedInUserType == 'customer'">
                <div class="col-12">
                  <h3 class="title-case">Find My contract</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 col-xl-6 d-xl-flex">
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                              formControlName="filter1select">
                              <option value="">Select Field</option>
                              <option value="firstname">First Name</option>
                              <option value="lastname">Last Name</option>
                              <option value="phone" [disabled]="keyfetchForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                              <option value="vin" [disabled]="keyfetchForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                            </select>           
                            <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                            <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                      </div>
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                              formControlName="filter2select">
                              <option value="" >Select Field</option>
                              <option value="contract">Contract No</option>
                              <option value="vin" [disabled]="keyfetchForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                              <option value="phone" [disabled]="keyfetchForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                            </select>                   
                            <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                        <button
                          type="button"
                          (click)="getMyContract()"
                          class="btn btn-info primary-background mr-2 mb-0"
                        >
                        <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                        Search & Fill
                        </button>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="show_claims">
                <div class="col-12">
                  <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
                  <div class="row">
                    <div class="col-12 d-xl-flex pl-5 pr-5">
                      <div class="table table-responsive smart-table">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Contract No</th>
                              <th>VIN</th>
                              <!--<th>Name</th>
                              <th>Phone No</th>
                              <th>Dealer</th> -->
                              <th>Product</th>
                              <th>Year</th>
                              <th>Make</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let claim of claims_data; let i=index"
                            >
                              <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                              <td>{{ claim.ContractNumber }}</td>
                              <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                              <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                              <td>{{ claim.PhysicalPhone | phone }}</td>
                              <td>{{ claim.DealerName }}</td> -->
                              <td>{{ claim.Product }}</td>
                              <td>{{ claim.VehicleYear }}</td>
                              <td>{{ claim.VehicleMake }}</td>
                              <td>{{ claim.VehicleModel }}</td>
                            </tr>
                          </tbody>
                        </table>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>        
              <hr class="mt-0" *ngIf="loggedInUserType == 'customer'"/>    
              <div class="row">
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Contract information</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4"><app-required-indication [control]="keyfetchForm.get('contractNumber')"
                          [label]="'Contract Number'"></app-required-indication>
                          <!-- <i class="fa fa-question-circle"
                          title="It helps you to pre-fill the basic contract details"
                        ></i> -->
                      </label>
                      <div class="col-12" 
                      [ngClass]="{
                        'col-xl-8': loggedInUserType == 'customer',
                        'col-xl-6': loggedInUserType != 'customer'
                      }">
                        <app-form-error-wrapper [control]="keyfetchForm.get('contractNumber')"
                          [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                          <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                        </app-form-error-wrapper>
                      </div>
                      <div class="col-12 col-xl-2" *ngIf="loggedInUserType != 'customer'">
                        <button
                          type="button"
                          (click)="getContract()"
                          class="btn btn-info primary-background mr-2"
                        >
                          Fill
                        </button>
                      </div>
                    </div>
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4 align-items-center">
                        <app-required-indication [control]="keyfetchForm.get('vinNumber')"
                        [label]="'VIN Number'"></app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="keyfetchForm.get('vinNumber')" [controlName]="'VIN Number'"
                          [apiErrorType]="'vinNumber'">
                          <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                            [formControl]="keyfetchForm.get('vinNumber')" />
                        </app-form-error-wrapper>
                      </div>
                      <!-- <div class="form-group col-2 d-xl-flex text-xl-center mb-0">
                        <button
                          type="button"
                          (click)="getContract()"
                          class="btn btn-info primary-background mr-2"
                        >
                          Fill
                        </button>
                      </div> -->
                    </div>
                    <!-- <div class="form-group col-12 d-xl-flex text-xl-center">
                      <button
                        type="button"
                        (click)="getContract()"
                        class="btn btn-info primary-background mr-2"
                      >
                        Fill
                      </button>
                    </div> -->
                  </div>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4">
                        <app-required-indication [control]="keyfetchForm.get('soldKeys')"
                          [label]="'Vehicle was sold with'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="keyfetchForm.get('soldKeys')"
                          [controlName]="'Vehicle was sold with'" [apiErrorType]="'soldKeys'">
                          <select class="custom-select form-control" name="soldKeys"
                            formControlName="soldKeys">
                            <option value="1">One Key</option>
                            <option value="2">Two Keys</option>
                          </select>
                        </app-form-error-wrapper>
                      </div>
                    </div>
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4 ">
                        <app-required-indication [control]="keyfetchForm.get('enterBy')"
                          [label]="'Entered By'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="keyfetchForm.get('enterBy')"
                          [controlName]="'Entered By'" [apiErrorType]="'enterBy'">
                          <select class="custom-select form-control" name="enterBy" (change)="checkEnterBy()"
                            formControlName="enterBy">
                            <option value="repair_facility">Repair facility</option>
                            <option value="customer">Customer</option>
                          </select>
                        </app-form-error-wrapper>
                      </div>
                    </div>
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4 ">
                        <app-required-indication [control]="keyfetchForm.get('paymentMethod')"
                          [label]="'Method of payment'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="keyfetchForm.get('paymentMethod')"
                          [controlName]="'Method of payment'" [apiErrorType]="'paymentMethod'">
                          <select class="custom-select form-control" name="paymentMethod" 
                            formControlName="paymentMethod">
                            <option value="payment" >Pay Shop Directly</option>
                            <option value="reimbursement">Reimbursement</option>
                          </select>
                        </app-form-error-wrapper>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0">
                      <!-- <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-6 p-0">
                          <app-required-indication [control]="keyfetchForm.get('contractNumber')"
                            [label]="'Contract Number'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-7 p-ls-xl-0">
                          <app-form-error-wrapper [control]="keyfetchForm.get('contractNumber')"
                            [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                            <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                          </app-form-error-wrapper>
                        </div>
                      </div> -->
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('claimDate')"
                            [label]="'Claim Date'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 pos-rel">
                          <app-form-error-wrapper [control]="keyfetchForm.get('claimDate')"
                            [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                            <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                              ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                              [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                                year: utilService.getCurrentYear,
                                month: utilService.getCurrentMonth,
                                day: utilService.getCurrentDay
                              }" />
                          </app-form-error-wrapper>
                          <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                            (click)="utilService.removeDate(keyfetchForm, 'claimDate')" placement="left"
                            ngbTooltip="Remove" *ngIf="keyfetchForm.controls['claimDate'].value">
                            <i class="ti-close text-danger m-r-10"></i>
                          </a>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('dealer')" [label]="'Selling Dealer'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('dealer')" [controlName]="'Dealer'"
                          [apiErrorType]="'dealer'">
                          <ng-template #rt let-r="result" let-t="term">
                            {{ r.name }}
                          </ng-template>
                          <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                            formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                            placeholder="Search and select Dealer" name="dealer" />
                        </app-form-error-wrapper>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Customer information </h3>
                  <div class="col-12 p-0">
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('customerName')"
                            [label]="'Customer Name'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="keyfetchForm.get('customerName')"
                            [controlName]="'Customer Name'" [apiErrorType]="'customerName'">
                            <input type="text" name="customerName" class="form-control" formControlName="customerName" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('phoneNumber')" [label]="'Phone Number'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="keyfetchForm.get('phoneNumber')"
                          [controlName]="'Phone Number'"
                          [apiErrorType]="'phoneNumber'"
                        >
                          <input
                            type="tel"
                            name="phonenumber"
                            mask="000-000-0000"
                            class="form-control"
                            [formControl]="keyfetchForm.controls['phoneNumber']"
                          />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('email')" [label]="'Email'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('email')" [controlName]="'Email'"
                            [apiErrorType]="'email'">
                            <input type="email" name="email" class="form-control" formControlName="email" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Address<sup *ngIf="isCustomer" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('streetAddress')"
                            [controlName]="'Street Address'" [apiErrorType]="'streetAddress'">
                            <input type="text" name="street-address" class="form-control" formControlName="streetAddress"  required="{{isCustomer}}"/>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          City<sup *ngIf="isCustomer" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('city')" [controlName]="'City'"
                            [apiErrorType]="'city'">
                            <input type="text" name="city" class="form-control" formControlName="city"  [required]="isCustomer"/>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">State<sup *ngIf="isCustomer" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('state')" [controlName]="'State'"
                            [apiErrorType]="'state'">
                            <select class="custom-select form-control" name="state" formControlName="state" [required]="isCustomer">
                              <option value="">Select State</option>
                              <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                            </select>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Zip Code<sup *ngIf="isCustomer" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('zipCode')" [controlName]="'Zip Code'"
                            [apiErrorType]="'zipCode'">
                            <input type="tel" class="form-control" [formControl]="keyfetchForm.controls['zipCode']"  required="{{isCustomer}}"
                              mask="00000" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Repair Shop Information</h3>
                  <div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center ">
                        <label class="col-12 col-xl-4">
                          Shop Name<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopName')"
                            [controlName]="'Repair Shop Name'" [apiErrorType]="'repairshopName'">
                            <input type="text" name="repairshopName" class="form-control"
                              formControlName="repairshopName" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center ">
                        <label class="col-12 col-xl-4">
                          Contact Name<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopContactName')"
                            [controlName]="'Contact Name'" [apiErrorType]="'repairshopContactName'">
                            <input type="text" name="repairshopContactName" class="form-control"
                              formControlName="repairshopContactName"   required="{{isRepairFacility}}" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center ">
                        <label class="col-12 col-xl-4">
                          Phone Number<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper
                          [control]="keyfetchForm.get('repairshopPhoneNumber')"
                          [controlName]="'repairshopPhoneNumber'"
                          [apiErrorType]="'repairshopPhoneNumber'"
                        >
                          <input
                            type="tel"
                            name="repairshopPhoneNumber"
                            mask="000-000-0000"
                            class="form-control"
                            [formControl]="keyfetchForm.controls['repairshopPhoneNumber']"
                            required="{{isRepairFacility}}" 
                          />
                          </app-form-error-wrapper>
                        </div>
                      </div>                  
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          Email<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopEmail')"
                            [controlName]="'Email'" [apiErrorType]="'repairshopEmail'">
                            <input type="text" name="repairshopEmail" class="form-control"
                              formControlName="repairshopEmail"   required="{{isRepairFacility}}" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          Address<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopAddress')"
                            [controlName]="'Street Address'" [apiErrorType]="'repairshopAddress'">
                            <input type="text" name="street-address" class="form-control"
                              formControlName="repairshopAddress"  required="{{isRepairFacility}}" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">                 
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          City<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopCity')" [controlName]="'City'"
                            [apiErrorType]="'repairshopCity'">
                            <input type="text" class="form-control" formControlName="repairshopCity" required="{{isRepairFacility}}" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          State<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopState')" [controlName]="'State'"
                            [apiErrorType]="'repairshopState'">
                            <select class="custom-select form-control" name="state" formControlName="repairshopState"  required="{{isRepairFacility}}" >
                              <option value="">Select State </option>
                              <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                            </select>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          Zip Code<sup *ngIf="isRepairFacility" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('repairshopZipCode')"
                            [controlName]="'Zip Code'" [apiErrorType]="'repairshopZipCode'">
                            <input type="tel" name="repairshopZipCode" class="form-control"
                              [formControl]="keyfetchForm.controls['repairshopZipCode']" minlength="5" maxlength="5"
                              mask="00000"   required="{{isRepairFacility}}" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Vehicle Information</h3>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('currentMileage')"
                            [label]="'Current Mileage'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="keyfetchForm.get('currentMileage')"
                          [controlName]="'Current mileage'"
                          [apiErrorType]="'currentMileage'"
                        >
                          <input
                            type="text"
                            name="currentMileage"
                            class="form-control"
                            mask="000000"
                            [formControl]="keyfetchForm.get('currentMileage')"
                          />
                        </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('year')" [label]="'Year'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="keyfetchForm.get('year')" [controlName]="'Year'"
                            [apiErrorType]="'year'">
                            <input type="text" name="year" mask="0000" class="form-control"
                              [formControl]="keyfetchForm.get('year')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('make')" [label]="'Make'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('make')" [controlName]="'Make'"
                            [apiErrorType]="'make'">
                            <input type="text" name="make" class="form-control"
                              [formControl]="keyfetchForm.get('make')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="keyfetchForm.get('model')" [label]="'Model'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="keyfetchForm.get('model')" [controlName]="'Model'"
                            [apiErrorType]="'model'">
                            <input type="text" name="model" class="form-control" formControlName="model" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-xl-8 col-12">
                  <h3 class="title-case">Customer Documents Needed</h3>
                    <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="keyfetchForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.RepairOrderInvoice">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.RepairOrderInvoice.replace('uploads/', '').replace('keyprotection/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.RepairOrderInvoice
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('RepairOrderInvoice')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.RepairOrderInvoice">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'RepairOrderInvoice')"
                            class="file-upload-width"
                          />
                          <a
                            *ngIf="keyfetchForm.get('RepairOrderInvoice').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              keyfetchForm.get('RepairOrderInvoice').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        Proof of payment
                        <sup *ngIf="isCustomer" style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="keyfetchForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.ProfOfPayment">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.ProfOfPayment.replace('uploads/', '').replace('keyprotection/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.ProfOfPayment
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('ProfOfPayment')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.ProfOfPayment">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'ProfOfPayment')"
                            class="file-upload-width"
                            required="{{isCustomer}}" 
                          />
                          <a
                            *ngIf="keyfetchForm.get('ProfOfPayment').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              keyfetchForm.get('ProfOfPayment').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        <app-required-indication [control]="keyfetchForm.get('reasonForReplace')"
                          [label]="'Reason & description for replacement'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-6">
                        <app-form-error-wrapper [control]="keyfetchForm.get('reasonForReplace')"
                          [controlName]="'Reason & description for replacement'" [apiErrorType]="'reasonForReplace'">
                          <textarea type="text" name="reasonForReplace" rows="5" class="form-control" formControlName="reasonForReplace"></textarea>
                        </app-form-error-wrapper>
                      </div>
                    </div>                 
                </div>
              </div>
              <div formArrayName="keyPhotos" *ngIf="keyPhotos" class="mb-5">
                <div class="form-group d-xl-flex align-items-center">
                  <!-- <label class="col-12">
                    <app-required-indication
                      [label]="
                        'Add More Documents'"
                    >
                    </app-required-indication>
                  </label> -->
                  <h3 class="title-case mr-2">Add More Documents</h3>
                  <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button>
                </div>
                <div *ngFor="let item of keyPhotos.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="form-group d-xl-flex align-items-center">
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-4 text-xl-center">
                          <app-required-indication
                            [control]="item.get('url')"
                            [label]="'Photo/Document'"
                          ></app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <input
                            type="file"
                            *ngIf="!showkeyPhoto[i]"
                            (change)="onChangeFile($event, i)"
                            name="url"
                            value="keyPhoto[i]"
                          />
                          <div *ngIf="showkeyPhoto[i]">
                            <label class="m-r-10">{{
                              keyPhoto[i] &&
                                keyPhoto[i].substr(
                                  keyPhoto[i].lastIndexOf('/') + 1
                                )
                            }}</label>
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit"
                              placement="right"
                              ngbTooltip="View Request"
                              href="{{ baseUrl }}{{
                                keyPhoto[i]
                              }}"
                              target="_blank"
                              ><i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                              (click)="changeImage(i)"
                              placement="top"
                              ngbTooltip="Change Image"
                            >
                              <i class="ti-move text-danger m-r-10"></i>
                            </a>
                          </div>
                      </div>
                      </div>
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-3">
                          <app-required-indication
                            [control]="item.get('name')"
                            [label]="'Document Description'"
                          >
                          </app-required-indication>
                        </label>
                        <div class="d-flex col-12 col-xl-9 p-0">
                          <div class="col-10">
                            <app-form-error-wrapper
                              [control]="item.get('name')"
                              [controlName]="'Title'"
                              [apiErrorType]="'name'"
                            >
                              <input
                                type="text"
                                name="title-{{ i }}"
                                class="form-control"
                                formControlName="name"
                              />
                            </app-form-error-wrapper>
                          </div>
                          <div class="col-2 text-right">
                            <button
                              class="btn btn-danger"
                              (click)="removeDoc(i)"
                            >
                              <i class="mdi mdi-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center ">
                  <label class="col-12 col-xl-6"></label>
                  <div class="col-12 col-xl-6 pl-5">
                    <!-- <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button
              type="button"
              (click)="cancelClick()"
              class="btn btn-secondary mr-2"
            >
              Cancel
            </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="keyfetchForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Claim'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="keyfetchForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Claim'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>