import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage.service';
import { LOCAL_VAR } from '@app/core/constants';

const credentialsKey = LOCAL_VAR.credentialsKey;
const domainconfig = LOCAL_VAR.domainconfig;

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _credentials: Authentication.Credentials | null;
  private _merchant: Authentication.Merchant | null;
  public merchant$ = new EventEmitter<Authentication.Merchant>();
  public credentials$ = new EventEmitter<Authentication.Credentials>();
  public domainconfig$ = new EventEmitter<boolean>();
  private _liveMode = false;
  public liveMode$ = new EventEmitter<boolean>();
  // private _merchants: Authentication.Merchant | null;

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    const savedCredentials = this.localStorageService.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
    if (this.isAuthenticated()) {
      this.refresh();
    }
  }

  refresh() {
    setTimeout(() => {
      this.me().subscribe(
        (user: Authentication.User) => {
          this._credentials.user = user;
          this.setCredentials(this._credentials);
          this.setConfig();
        },
        (error: any) => {
          console.log(`get merchants error: ${error}`);
        }
      );
    }, 1);
  }

  // setConfig():Promise<any[]> {
  //   return new Promise((resolve, reject) =>{
  //     this.httpClient.get('/auth/config').subscribe((response: any) => {
  //       console.log(response);
  //       resolve(response);

  //     }, reject);

  //    })
  // }

  setLocalConfig(config:any){
    this.localStorageService.setItem(
      domainconfig,
      JSON.stringify(config));
  } 
  
  clearConfig(){
    this.localStorageService.clearItem(domainconfig);
  }

  setConfig(){
    setTimeout(() => {
      this.config().subscribe(
        (config: any) => {
          //console.log(config);
          if(config && config.records){
            this.setLocalConfig(config.records);
          } else {
            this.clearConfig();
          }
          return config;
        },
        (error: any) => {
          this.localStorageService.clearItem(domainconfig);
          console.log(`get merchants error: ${error}`);
        }
      );      
    }, 1);
  }

  me(): Observable<Authentication.User> {
    return this.httpClient.get('/auth/me').pipe(
      map((body: Authentication.User) => {
        return body;
      })
    );
  }

  config() {
    return this.httpClient.get('/auth/config').pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getconfig(domain:string) {
    return this.httpClient.get(`/auth/config/${domain}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  login(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/login', payload).pipe(
      map((body: Authentication.Credentials) => {
        this.setCredentials(body);
        return body;
      })
    );
  }

  otplogin(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/otplogin', payload).pipe(
      map((body: Authentication.Credentials) => {
        this.setCredentials(body);
        return body;
      })
    );
  }

  setPassword(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/activation', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  forgotPassword(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/forgotpassword', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  resetPassword(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/updatepassword', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  /* signup(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.post('/auth/signup', payload).pipe(
      map((body: any) => {
        //this.setCredentials(body);
        return body;
      })
    );
  } */

  signup(
    payload: Authentication.SignupPayload
  ): Observable<Authentication.User> {
    return this.httpClient.post('/auth/signup', payload).pipe(
      map((body: Authentication.User) => {
        return body;
      })
    );
  }

  verifyemail(payload: any) {
    return this.httpClient.post(`/auth/verifyemail`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getotp(payload: any) {
    return this.httpClient.post('/auth/getotp', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  /* 
  verifyEmail_1(emailHash: string): Observable<Authentication.User> {
    return this.httpClient.get(`/auth/verifyEmail?emailHash=${emailHash}`).pipe(
      map((body: Authentication.User) => {
        return body;
      })
    );
  } */

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(flag?: boolean): Observable<boolean> {
    if (flag) {
      this.setCredentials();
      return of(true);
    } else {
      return this.httpClient
        .post('/logout', {
          sessionId: this.credentials.session
        })
        .pipe(
          map(() => {
            this.setCredentials();
            return true;
          })
        );
    }
  }

  uploadPhoto(payload: any): Observable<any> {
    return this.httpClient.put('/auth/uploadFile', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteFile(payload: any): Observable<any> {
    return this.httpClient.put('/auth/deleteFile', payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteOtherFile(payload: any): Observable<any> {
   return this.httpClient.put(`/claim/${payload.claimId}/updatedocs`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Authentication.Credentials | null {
    return this._credentials;
  }

  /**
   * Get the auth token.
   * @return {string} The auth token is null if user is not authenticated.
   */
  get accessToken(): string | null {
    return this.credentials ? this.credentials.accessToken : null;
  }

  /**
   * Get the auth token.
   * @return {string} The auth token is null if user is not authenticated.
   */
  get merchant(): Authentication.Merchant | null {
    return this._merchant ? this._merchant : null;
  }

  /**
   * Get the auth token.
   * @return {string} The auth token is null if user is not authenticated.
   */
  get sessionId(): string | null {
    return this.credentials ? this.credentials.session : null;
  }

  /**
   * Sets the user credentials.
   * @param {Credentials=} Authentication.Credentials The user credentials.
   */
  private setCredentials(credentials?: Authentication.Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      this.localStorageService.setItem(
        credentialsKey,
        JSON.stringify(credentials)
      );
      this.credentials$.emit(this._credentials);
      if (this.credentials.user.userRoleId.slug === 'customer') {
        this.localStorageService.setItem(
          LOCAL_VAR.selectedCustomer,
          JSON.stringify(this.credentials.user)
        );
      }
    } else {
      this.localStorageService.clearItem(credentialsKey);
    }
  }

  /**
   * Get Live mode value
   * @return {boolean} True if livemode enabled
   */
  get liveMode(): boolean {
    return this._liveMode;
  }

  /**
   * Set Live mode value
   * @liveMode {boolean}
   */
  set liveMode(liveMode: boolean) {
    this._liveMode = liveMode;
    this.liveMode$.emit(this.liveMode);
  }

  get loggedInUserType() {
    if (
      this.credentials &&
      this.credentials.user &&
      this.credentials.user.userRoleId &&
      this.credentials.user.userRoleId.slug
    ) {
      return this.credentials.user.userRoleId.slug;
    } else {
      return '';
    }
  }
}
