import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import {
  finalize,
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { debitcancelService } from '@app/core/service/debitcancel.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';
import { NgxCurrencyModule } from "ngx-currency";


@Component({
  selector: 'app-debitcancellation-create',
  templateUrl: './debitcancellation-create.component.html',
  styleUrls: ['./debitcancellation-create.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class DebitcancellationCreateComponent implements OnInit {
  debitForm: FormGroup;
  additionDocs: FormArray;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  roles: string[];user: any;
  loggedInUserType: string;
  loggedinuserdetail: any;
  claimData: any;
  isreivewMode = false;
  isEditMode = false;
  isAdmin: boolean = null;
  claimId: any = null;
  baseUrl = '';
  uploadedImg: any = {
    RepairOrderInvoice: null
  };
  selectedCustomer: any = null;
  additionDoc: any = [];
  showadditionDoc: any = [];
  filter1_name: any;
  filter1_value: any;
  filter2_name: any;
  filter2_value: any;
  claims_data: any;
  show_claims = false;
  getcontract_loading = false;

  @ViewChild('instance') instance: any;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  dealersList: [{ name: 'DOWC'; _id: '0' }];
  dealerName: string;
  //formatter = (result: string) => result.toUpperCase();
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.dealersList
            .filter(
              v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      )
    );
  formatter = (x: { name: string }) => x.name;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private claimService: debitcancelService,
    private Claims: ClaimService,
    private authenticationService: AuthenticationService,    
    config: NgbTypeaheadConfig
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.baseUrl = environment.s3Url;
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    if (this.router.url.indexOf('review') !== -1) {
      this.isreivewMode = true;
    } else {
      this.isreivewMode = false;
    }
    if (this.router.url.indexOf('edit') !== -1) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });

    if (this.isEditMode && this.route.snapshot.params['claimId']) {
      this.claimId = this.route.snapshot.params['claimId'];
    }
    if (this.claimId) {
      this.getLeaseDealbyId(this.claimId);
    }
    this.createForm();
    this.getDealers();
  }

  ngOnInit() {
    if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
      this.router.navigate(['/products'], { replaceUrl: true });
    }
  }

  private createForm() {

    if (this.claimData && (this.loggedInUserType === 'dowcsuperadmin' || this.loggedInUserType === 'superadmin')) {
      this.isAdmin = true;
    }

    this.debitForm = this.formBuilder.group({
      contractNumber: [
        this.claimData ? this.claimData.contractNumber : '',
        [Validators.required]
      ],
      claimDate: [
        this.claimData
          ? this.claimData.claimDate
            ? DateTime.fromISO(this.claimData.claimDate).toObject()
            : null
          : '',
        [Validators.required]
      ],
      debitorName: [
        this.claimData ? this.claimData.debitorName : '',
        [Validators.required]
      ],
      debitorEmail: [
        this.claimData ? this.claimData.debitorEmail : this.user.email,
        [Validators.required, Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
      ],
      debitorStreet: [
        this.claimData ? this.claimData.debitorStreet : '',
        Validators.required
      ],
      debitorCity: [this.claimData ? this.claimData.debitorCity : '', Validators.required],
      debitorState: [this.claimData ? this.claimData.debitorState : '', Validators.required],
      debitorZip: [
        this.claimData ? this.claimData.debitorZip : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      debitorPhone: [
        this.claimData ? this.claimData.debitorPhone : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      codebitorName: [
        this.claimData ? this.claimData.codebitorName : '',
        []
      ],
      codebitorEmail: [
        this.claimData ? this.claimData.codebitorEmail : '',
        [Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
      ],
      codebitorStreet: [
        this.claimData ? this.claimData.codebitorStreet : '',
        []
      ],
      codebitorCity: [this.claimData ? this.claimData.codebitorCity : ''],
      codebitorState: [this.claimData ? this.claimData.codebitorState : ''],
      codebitorZip: [
        this.claimData ? this.claimData.codebitorZip : '',
        Validators.compose([
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      codebitorPhone: [
        this.claimData ? this.claimData.codebitorPhone : '',
        Validators.compose([
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      year: [
        this.claimData && this.claimData.year ? this.claimData.year : null,
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ])
      ],
      make: [
        this.claimData && this.claimData.make ? this.claimData.make : null,
        Validators.required
      ],
      model: [
        this.claimData && this.claimData.model ? this.claimData.model : null,
        Validators.required
      ],
      currentMileage: [
        this.claimData && this.claimData.currentMileage
          ? this.claimData.currentMileage
          : null,
        Validators.required
      ],
      vinNumber: [
        this.claimData && this.claimData.vinNumber
          ? this.claimData.vinNumber
          : null,
        Validators.compose([Validators.required, Validators.minLength(17)])
      ],
      trim: [
        this.claimData ? this.claimData.trim : '',
        [Validators.required]
      ],
      vehicleType: this.formBuilder.group({
        isNew: [
          this.claimData ? this.claimData.vehicleType.isNew : false
        ],
        isPreOwned: [
          this.claimData ? this.claimData.vehicleType.isPreOwned : false
        ],
        isCertifiedPreOwned: [
          this.claimData ? this.claimData.vehicleType.isCertifiedPreOwned : false
        ]
      }),
      dealer: [
        this.claimData
          ? this.claimData.dealer == null
            ? ''
            : {
                _id: this.claimData.dealer._id,
                name: this.claimData.dealer.name
              }
          : '',
        /* this.claimData ? this.claimData.dealer : '', */
        Validators.required
      ],
      dealerStreet: [
        this.claimData ? this.claimData.dealerStreet : '',
        Validators.required
      ],
      dealerCity: [this.claimData ? this.claimData.dealerCity : '', Validators.required],
      dealerState: [this.claimData ? this.claimData.dealerState : '', Validators.required],
      dealerZip: [
        this.claimData ? this.claimData.dealerZip : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      dealerPhone: [
        this.claimData ? this.claimData.dealerPhone : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      lienholderName: [
        this.claimData ? this.claimData.lienholderName : '',
        [Validators.required]
      ],
      lienholderStreet: [
        this.claimData ? this.claimData.lienholderStreet : '',
        Validators.required
      ],
      lienholderCity: [this.claimData ? this.claimData.lienholderCity : '', Validators.required],
      lienholderState: [this.claimData ? this.claimData.lienholderState : '', Validators.required],
      lienholderZip: [
        this.claimData ? this.claimData.lienholderZip : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      lienholderPhone: [
        this.claimData ? this.claimData.lienholderPhone : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      agreementPurchasePrice: [
        this.claimData ? this.claimData.agreementPurchasePrice : '',
        [Validators.required]
      ],
      liabilitySettlement: [
        this.claimData ? this.claimData.liabilitySettlement : ''
      ],
      comprehensiveOrCollisionSettlement: [
        this.claimData ? this.claimData.comprehensiveOrCollisionSettlement : ''
      ],
      accidentOrPoliceReport: [
        this.claimData ? this.claimData.accidentOrPoliceReport : ''
      ],
      fireReport : [
        this.claimData ? this.claimData.fireReport : ''
      ],
      estimateDamageRepair: [
        this.claimData ? this.claimData.estimateDamageRepair : ''
      ],
      debtCancellationAgreement: [
        this.claimData ? this.claimData.debtCancellationAgreement : ''
      ],
      vehiclesOriginalBill: [
        this.claimData ? this.claimData.vehiclesOriginalBill : ''
      ],
      historyOfFinanceContract: [
        this.claimData ? this.claimData.historyOfFinanceContract : ''
      ],
      payOffStatement: [
        this.claimData ? this.claimData.payOffStatement : ''
      ],
      refundChecksOrAuthorizedCancellation: [
        this.claimData ? this.claimData.refundChecksOrAuthorizedCancellation : ''
      ],
      windowStickerOrMSRP: [
        this.claimData ? this.claimData.windowStickerOrMSRP : ''],
      swornProofOfLossStatement: [
        this.claimData ? this.claimData.swornProofOfLossStatement : ''
      ],
      additionDocs: this.formBuilder.array([]),      
      agreementPurchaseDate: [
        this.claimData
          ? this.claimData.agreementPurchaseDate
            ? DateTime.fromISO(this.claimData.agreementPurchaseDate).toObject()
            : null
          : '',
        [Validators.required]
      ],
      maximunBenefitLimit: [
        this.claimData ? this.claimData.maximunBenefitLimit : '',
        [Validators.required]
      ],
      vehiclePurchasePrice: [
        this.claimData ? this.claimData.vehiclePurchasePrice : '',
        [Validators.required]
      ],
      dateOfFirstPayment: [
        this.claimData
          ? this.claimData.dateOfFirstPayment
            ? DateTime.fromISO(this.claimData.dateOfFirstPayment).toObject()
            : null
          : '',
        [Validators.required]
      ],
      APR: [
        this.claimData ? this.claimData.APR : 0,
        Validators.compose([
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(3)
        ])
      ],
      monthlyPaymentAmount: [
        this.claimData ? this.claimData.monthlyPaymentAmount : '',
        [Validators.required]
      ],
      amountFinanced: [
        this.claimData ? this.claimData.amountFinanced : '',
        [Validators.required]
      ],
      financingContractType: this.formBuilder.group({
        retailInstallmentSales: [
          this.claimData ? this.claimData.financingContractType.retailInstallmentSales : false
        ],
        lease: [
          this.claimData ? this.claimData.financingContractType.lease : false
        ]
      }),
      totalOfAllPayement: [
        this.claimData ? this.claimData.totalOfAllPayement : '',
        [Validators.required]
      ],
      outstandingAmountFinanced: [
        { value: this.claimData ? this.claimData.outstandingAmountFinanced : '', disabled: true },
        this.isAdmin ? Validators.required : ''
      ],
      cancelledProducts: [
        this.claimData ? this.claimData.cancelledProducts : '',
        this.isAdmin ? [Validators.required] : ''
      ],
      subTotal: [
        { value: this.claimData ? this.claimData.subTotal : '',disabled: true },
        this.isAdmin ? [Validators.required] : ''
      ],
      consumerLiability: [
        this.claimData ? this.claimData.consumerLiability : '',
        this.isAdmin ? [Validators.required] : ''
      ],
      maximumDebtCancellation: [
        { value: this.claimData ? this.claimData.maximumDebtCancellation : '',disabled: true },
        this.isAdmin ? [Validators.required] : ''
      ],
      truncatedAmount: [
        { value: this.claimData ? this.claimData.truncatedAmount : '',disabled: true },
        this.isAdmin ? [Validators.required] : ''
      ],
      salvageCredit: [
        { value: this.claimData ? this.claimData.salvageCredit : '',disabled: true },
        this.isAdmin ? [Validators.required] : ''
      ],
      finalPayout: [
        { value: this.claimData ? this.claimData.finalPayout : '',disabled: true },
        this.isAdmin ? [Validators.required] : ''
      ],
      filter1select: [''],
      filter1: [],
      filter2select: [''],
      filter2: []
    });
    // this.formControlsValueChanges();
    // this.debitForm.valueChanges.subscribe(values => {
    //   this.GAPCalculation();
    // });

    this.additionDocs = this.debitForm.get('additionDocs') as FormArray;
    if (
      this.isEditMode &&
      this.claimData &&
      this.claimData.additionDocs &&
      this.claimData.additionDocs.length > 0
    ) {
      this.claimData.additionDocs.forEach((addition: any, index: number) => {
        this.additionDocs.push(this.createadditionDocsForm(addition));
        this.additionDoc.push(addition.url);
        this.showadditionDoc.push(addition.url ? true : false);
      });
    } else {
      //this.additionDocs.push(this.createadditionDocsForm());
    }

    this.debitForm.get('dealer').valueChanges.subscribe(values => {
      this.checkDealer();
    });

    if (this.isAdmin) {
      this.updateValidator();
      this.GAPCalculation();
    }

  }

  updateValidator() {
    this.debitForm.get('outstandingAmountFinanced').setValidators([Validators.required]);
    this.debitForm.get('cancelledProducts').setValidators([Validators.required]);
    this.debitForm.get('subTotal').setValidators([Validators.required]);
    this.debitForm.get('consumerLiability').setValidators([Validators.required]);
    this.debitForm.get('maximumDebtCancellation').setValidators([Validators.required]);
    this.debitForm.get('truncatedAmount').setValidators([Validators.required]);
    this.debitForm.get('salvageCredit').setValidators([Validators.required]);
    this.debitForm.get('finalPayout').setValidators([Validators.required]);
  }

  ngAfterViewInit() {
    $("app-required-indication").each(function (index, element) {
      var text = element.innerHTML;
      element.innerHTML = text.replace('\n<!--', '<!--');
    });
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.secondaryColor){
        this.utilService.changeCssAttribute('card-header','background-color',domainconfig.secondaryColor);
      }
    } 
  }

  selectCheckbox(control: any, e: any, group?: any) {
    if (group) {
      const formGroup: any = this.debitForm.controls[group];
      formGroup.controls[control].setValue(e.target.checked);
      formGroup.controls[control].markAsDirty();
    } else {
      this.debitForm.controls[control].setValue(e.target.checked);
      this.debitForm.controls[control].markAsDirty();
    }
  }

  getDealername(dealer: any) {
    if (dealer.name) {
      this.dealerName = dealer.name;
    }
  }

  submitData() {
    // this.onSubmitStepChange.emit();
    // if (this.selectedCustomer) {
    // this.isLoading = true;
    let payload = this.debitForm.getRawValue();
    payload = this.utilService.removeEmpty(payload);
    payload.userId = this.selectedCustomer
      ? this.selectedCustomer._id
      : this.user._id
        ? this.user._id
        : null;
    if (payload.claimDate) {
      payload.claimDate = DateTime.fromObject(
        payload.claimDate
      ).toISO();
    }
    if (payload.agreementPurchaseDate) {
      payload.agreementPurchaseDate = DateTime.fromObject(
        payload.agreementPurchaseDate
      ).toISO();
    }
    if (payload.dateOfFirstPayment) {
      payload.dateOfFirstPayment = DateTime.fromObject(
        payload.dateOfFirstPayment
      ).toISO();
    }
    let docs = this.additionDoc.filter((a: any) => a);
    if(docs && docs.length>0){
      payload.additionDocs.map((docs: any, index: number) => {
        docs.url = this.additionDoc[index]
          ? this.additionDoc[index]
          : null;
          this.showadditionDoc[index] = true;
      });
    } else {
      payload.additionDocs = null;
    }
    
    if (this.claimData) {
      this.claimService
        .update(this.claimId, payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your claim.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/debitcancellation-list']);
        });
    } else {
      this.claimService
        .add(payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your claim.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/debitcancellation-list']);
        });
    }
  }

  getDealers() {
    this.customerService
      .getDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          this.dealersList = response.records;
        }
      });
  }

  getLeaseDealbyId(DealId: string) {
    this.claimService
      .getById(DealId)
      .subscribe((response: any) => {
        if (response.records) {
          this.claimData = response.records;
          this.createForm();
          if (this.isAdmin)
            this.updateValidator();
        } else if (this.isEditMode) {
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
          this.router.navigate(['/debitcancellation-list']);
        }
      });
  }

  onSelectFile(event: any, type: any) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      const formData: FormData = new FormData();
      formData.append('file', event.target.files[0]);
      if (this.claimData)
        formData.append('claimId', this.claimData._id);
      formData.append('fieldName', type);
      formData.append('prod', 'debitcancellation');
      this.authenticationService.uploadPhoto(formData).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.debitForm.controls[type].setValue(res.path);
          this.debitForm.controls[type].markAsDirty();
          //this.debitForm.controls[type].setValue(null);
          //TODO - change ui
          this.uploadedImg[type] = res.path;
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.toastrService.warning('Invalid file');
        }
      );
    }
  }

  async removeImage(type: any) {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'files.deleteFiles.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'files.deleteFiles.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'files.deleteFiles.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'files.deleteFiles.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'files.deleteFiles.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'files.deleteFiles.method',
      CONFIRMATION_MSGS
    );
    let payload: any;
    if (this.claimData) {
      payload = { claimId: this.claimData._id, fieldName: type, prod: 'debitcancellation' };
    } else {
      let filename = this.debitForm.controls[type].value;
      payload = { fieldName: filename, prod: 'debitcancellation' };
    }

    modalRef.componentInstance.payload = [payload];

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.toastrService.info('Document deleted Successfully!..');
      this.isLoading = false;
      this.uploadedImg[type] = null;
      this.debitForm.controls[type].setValue(null);
      this.debitForm.controls[type].markAsDirty();
    }

  }

  cancelClick() {
    this.router.navigate(['/debitcancellation-list']);
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omit_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ( (k > 95 && k <= 105) || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  GAPCalculation() {
    // var totalpaid = this.debitForm.controls['monthlyPaymentAmount'].value * this.debitForm.controls['APR'].value;
    // this.debitForm.get('totalOfAllPayement').setValue(totalpaid ? totalpaid : 0);
    if (this.isAdmin) {
      let outstating = this.debitForm.controls['amountFinanced'].value - this.debitForm.controls['totalOfAllPayement'].value;
      this.debitForm.get('outstandingAmountFinanced').setValue(outstating ? outstating : 0);
      let subtotal = outstating - this.debitForm.controls['cancelledProducts'].value;
      if (subtotal > this.debitForm.controls['maximunBenefitLimit'].value)
        subtotal = this.debitForm.controls['maximunBenefitLimit'].value
      this.debitForm.get('subTotal').setValue(subtotal ? subtotal : 0);
      let maxDebit = subtotal - this.debitForm.controls['consumerLiability'].value;
      this.debitForm.get('maximumDebtCancellation').setValue(maxDebit ? maxDebit : 0);
      this.debitForm.get('truncatedAmount').setValue(maxDebit ? maxDebit : 0);
      let salvageCredit = this.debitForm.controls['vehiclePurchasePrice'].value * 0.10;
      this.debitForm.get('salvageCredit').setValue(salvageCredit ? salvageCredit : 0);
      let finalpayout = this.debitForm.controls['truncatedAmount'].value - salvageCredit;
      this.debitForm.get('finalPayout').setValue(finalpayout ? finalpayout : 0);
    }
  }

  getMyContract() {
    this.getcontract_loading = true;
    this.filter1_name = this.debitForm.controls.filter1select.value;
    this.filter1_value = $.trim(this.debitForm.controls.filter1.value);
    this.filter2_name = this.debitForm.controls.filter2select.value;
    this.filter2_value = $.trim(this.debitForm.controls.filter2.value);
    // if(this.filter1_name == '' || this.filter1_value == '' || this.filter2_name == '' || this.filter2_value =='')
    // {
    //   this.toastrService.warning('please select all fields and values');
    //   return true;
    // }
    // console.log(this.filter1_name,'1',this.filter1_value,'2',this.filter2_name,'3',this.filter2_value,'4')
    // var data = { [filter1_name] : filter1_value, [filter2_name]: filter2_value };
    if (this.filter1_name && this.filter1_value && this.filter2_name && this.filter2_value) {

      var data = {
        "firstname": this.filter1_name == 'firstname' ? $.trim(this.filter1_value) : '',
        "lastname": this.filter1_name == 'lastname' ? $.trim(this.filter1_value) : '',
        "phone": this.filter1_name == 'phone' ? $.trim(this.filter1_value) : (this.filter2_name == 'phone' ? $.trim(this.filter2_value) : ''),
        "vin": this.filter1_name == 'vin' ? $.trim(this.filter1_value) : (this.filter2_name == 'vin' ? $.trim(this.filter2_value) : ''),
        "contract": this.filter2_name == 'contract' ? $.trim(this.filter2_value) : '',
        "ProductType": "ANC",
        "ProductSubType": "",
        "Product": ""
      }
      this.Claims
        .getContractDetails(data)
        .subscribe((res: any) => {
          this.getcontract_loading = false;
          if (res.status === 1) {
            this.toastrService.info('The contract information provided is for reference only. Please edit or make any changes you deem necessary.', 'We have found a contract matching your search!');
            if (res.data.length == 1) {
              // this.show_claims = true;
              this.setContractData(res.data[0]);
              // this.claims_data = res.data;
            }
            else {
              this.claims_data = res.data;
              this.show_claims = true;
            }
          } else {
            this.show_claims = false;
            this.toastrService.warning("Try a different combination or fill in the details manually.", "Sorry! we couldn't find that contract!");
            this.resetContractData();
          }
        });
    }
    else {
      this.toastrService.warning('please select / fill all fields and values');
      this.show_claims = false;
      this.getcontract_loading = false;
      return true;
    }
  }

  getContract() {
      var contactNumber = $.trim(this.debitForm.controls.contractNumber.value);
    if (contactNumber !== '') {
      var data = { "contractNo": contactNumber, "productType":"ANC" };
      this.Claims
        .getContractInfo(data)
        .subscribe((res: any) => {
          if (res.status === 1) {
            this.toastrService.info(res.msg);
            this.setContractData(res.data);
          } else {
            this.toastrService.warning(res.msg);
            this.resetContractData();
          }
        });
    } else {
      this.toastrService.warning('please fill Contract No');
    }
  }

  claim_select(event: any) {
    // console.log(event.target.value,'valuuuuu')
    this.setContractData(this.claims_data[event.target.value]);
    this.show_claims = false;
  }

  setContractData(data: any) {
    this.debitForm.controls['contractNumber'].setValue(data.ContractNumber);
    this.debitForm.controls['vinNumber'].setValue(data.VIN);
    this.debitForm.controls['debitorName'].setValue(data.FirstName ? data.FirstName : '' + ' ' + data.LastName ? data.LastName : '');
    this.debitForm.controls['debitorPhone'].setValue(data.PhysicalPhone);
    let address = '';
    if (data.PhysicalAddressLine1)
      address = data.PhysicalAddressLine1
    if (data.PhysicalAddressLine2)
        address = address + data.PhysicalAddressLine1 ? ', ' : ' ' + data.PhysicalAddressLine2      
    this.debitForm.controls['debitorStreet'].setValue(address);
    this.debitForm.controls['debitorCity'].setValue(data.PhysicalCity);
    this.debitForm.controls['debitorState'].setValue(data.PhysicalState);
    this.debitForm.controls['debitorZip'].setValue(data.PhysicalZipCode);
    this.debitForm.controls['make'].setValue(data.VehicleMake);
    this.debitForm.controls['model'].setValue(data.VehicleModel);
    this.debitForm.controls['year'].setValue(data.VehicleYear);
    this.debitForm.controls['dealer'].setValue(this.dealersList.find((user: any) => { return (user.name.toLowerCase() == data.DealerName.toLowerCase() /*||  user.dealerNo == data.dealerNo */) }));
  }

  resetContractData() {
    //this.debitForm.controls['contractNumber'].setValue('');
    //this.debitForm.controls['vinNumber'].setValue('');
    this.debitForm.controls['debitorName'].setValue('');
    this.debitForm.controls['debitorPhone'].setValue('');
    this.debitForm.controls['debitorStreet'].setValue('');
    this.debitForm.controls['debitorCity'].setValue('');
    this.debitForm.controls['debitorState'].setValue('');
    this.debitForm.controls['debitorZip'].setValue('');
    this.debitForm.controls['make'].setValue('');
    this.debitForm.controls['model'].setValue('');
    this.debitForm.controls['year'].setValue('');
  }

  createadditionDocsForm(data?: any) {
    return this.formBuilder.group({
      name: [
        (this.isEditMode && data && data.name)
          ? data.name
          : '',
        [Validators.required]
      ],
      url: [
        (this.isEditMode  && data && data.url)
          ? data.url
          : '',
          [Validators.required]
      ]
    });
  }

  addDoc(): void {
    this.additionDocs = this.debitForm.get('additionDocs') as FormArray;
    this.additionDocs.push(this.createadditionDocsForm());
  }

  removeDoc(index: number): void {
    this.debitForm.markAsDirty();
    this.additionDoc[index] = '';
    this.showadditionDoc[index] = false;
    this.additionDocs.removeAt(index);
  }

  onChangeFile(event: any, index: number) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      var phots=this.additionDocs.controls[index];
      const formData: FormData = new FormData();
      formData.append('file', event.target.files[0]);
      // if (this.claimData)
      //   formData.append('claimId', this.claimData._id);
      formData.append('fieldName', 'additionDocs');
      formData.append('prod', 'debitcancellation');
      this.authenticationService.uploadPhoto(formData).subscribe(
        (res: any) => {
          this.additionDocs.markAsDirty();
          this.isLoading = false;
          this.additionDoc[index] = res.path;
          this.additionDocs.controls[index].get('url').setValue(res.path);
          this.showadditionDoc[index] = true;
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.toastrService.warning('Invalid file');
        }
      );
    }
  }

  changeImage(index: number) {
    this.additionDocs.markAsDirty();
    this.additionDoc[index] = '';
    this.showadditionDoc[index] = false;
    this.additionDocs.controls[index].get('url').setValue(null);
  }


  checkDealer(){
    if(typeof this.debitForm.controls['dealer'].value !=='object'){
      this.debitForm.controls.dealer.setErrors({ 'incorrect': true});
    }
  }

  checkValidfields() {
    if (this.debitForm.invalid || this.isLoading) {
      this.toastrService.warning('Please fill all required fields.');
      for (const i in this.debitForm.controls) {
        this.debitForm.controls[i].markAsTouched();
        const control = this.debitForm.controls[i] as FormGroup | FormArray;
        if (control instanceof FormGroup) {
          for (const j in control.controls) control.controls[j].markAsTouched();
          // this.debitForm.controls[i]
        }
        if (control instanceof FormArray) {
          const formarraycontrol = control.controls[0] as FormGroup;
          if (formarraycontrol instanceof FormGroup) {
            for (const i in formarraycontrol.controls)
              formarraycontrol.controls[i].markAsTouched();
          }
        }
      }

      console.log(this.debitForm, 'validddd1');
    }
  }

}
