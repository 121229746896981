import { HttpClient, HttpParams } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable, EventEmitter } from '@angular/core';
import { COUNTRIES, DATE_TIME_FORMATE, LOCAL_VAR, TIMEZONES } from '@app/core/constants';
import { FormGroup } from '@angular/forms';

@Injectable()
export class UtilService {
  datePipe = new DatePipe('en-US');
  private _missedError = '';
  public missedErrors$ = new EventEmitter<string>();
  stepClick = true;
  selectedProducts: any[] = [];
  $onProductChange: EventEmitter<any> = new EventEmitter<any>();
  public clickedOnStep$ = new EventEmitter<any>();
  constructor(private currencyPipe: CurrencyPipe) { }

  getCountries(available?: boolean): Country.Object[] {
    return COUNTRIES.filter(c =>
      available !== undefined ? c.available === available : true
    );
  }

  getTimeZones(): any[] {
    const timezones: any = [];
    TIMEZONES.map(t => {
      return t.utc.map(u => {
        if (u.indexOf('America') !== -1) {
          timezones.push({
            key: t.abbr,
            value: u
          });
        }
      });
    });
    return timezones;
  }

  dollarToCents(dollar: string | number) {
    return parseInt((parseFloat(dollar.toString()) * 100).toString(), 10);
  }

  centsToDollar(cents: string | number) {
    return parseFloat(cents.toString()) / 100;
  }

  removeEmpty(obj: any): any {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        (Array.isArray(obj[propName]) && !obj[propName].length) ||
        (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  removeWhiteSpace(obj: any): any {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        $.trim(obj[propName]) === '' ||
        (Array.isArray(obj[propName]) && !obj[propName].length) ||
        (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))
      ) {
        delete obj[propName];
      } else if (typeof obj[propName] != "object") {
        obj[propName] = $.trim(obj[propName]);
      }
    }
    return obj;
  }

  isEmpty(obj: any): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  stringKeywordReplacer(msg: string, apiObject: any) {
    try {
      return msg.replace(/{([^}]+)}/g, (match, m1) => {
        if (m1.includes('$')) {
          return Math.abs(this.centsToDollar(this.get(apiObject, m1.slice(1))));
        }
        if (m1.includes('#')) {
          return this.datePipe.transform(
            this.get(apiObject, m1.slice(1)),
            DATE_TIME_FORMATE.APIKEY
          );
        }
        return this.get(apiObject, m1);
      });
    } catch (e) {
      console.log(e);
    }
    return msg;
  }

  objectResolver(path: string, obj: any) {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  get(obj: any, key: any): any {
    return key.split('.').reduce((o: any, x: any) => {
      return o === undefined || o === null ? o : o[x];
    }, obj);
  }

  generateWildcardObject(id: string): string {
    try {
      let object = '';
      const prefix = id.split('_')[0];

      object = JSON.stringify({
        $or: [
          {
            'data.object._id': id
          },
          {
            // ['data.object.' + INDENTIFIERS[prefix]]: id
          }
        ]
      });
      return object;
    } catch (e) {
      console.log(e);
    }
  }

  createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (const property in object) {
      if (
        !object.hasOwnProperty(property) ||
        (object[property] === null || object[property] === undefined)
      ) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File)
      ) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  setValidators(
    form: FormGroup,
    control: string,
    validators: any[]
  ): FormGroup {
    form.setValidators(validators);
    form.get(control).updateValueAndValidity();
    return form;
  }

  clearValidators(form: FormGroup, control: string): FormGroup {
    form.clearValidators();
    form.get(control).updateValueAndValidity();
    return form;
  }

  // For missed API Errors
  get missedErrors(): string | null {
    return this._missedError;
  }

  public setMissedError(errMsg: any) {
    this._missedError = errMsg;
    this.missedErrors$.emit(this._missedError);
    console.log(this.missedErrors, 'this.missedErrors EMIT');
  }

  public clearMissedError() {
    this._missedError = '';
    this.missedErrors$.emit(this._missedError);
  }

  showPaginationInfoOld(data: any, perPage: any, currentPage: any) {
    let lastPage = data.length / perPage;
    if (!this.isInt(lastPage)) {
      lastPage = Math.floor(lastPage) + 1;
    }
    if (data && data.length > 0) {
      const from = perPage * currentPage - perPage + 1;
      const to = perPage * currentPage;
      if (data.length % perPage === 0) {
        return `Showing ${from} to ${to} of ${data.length} entries`;
      } else {
        if (currentPage === lastPage) {
          return `Showing ${from} to ${(data.length % perPage) + from - 1} of ${
            data.length
            } entries`;
        } else {
          return `Showing ${from} to ${to} of ${data.length} entries`;
        }
      }
    } else {
      return '';
    }
  }

  showPaginationInfo(total: any, perPage: any, currentPage: any) {
    let lastPage = total / perPage;
    if (!this.isInt(lastPage)) {
      lastPage = Math.floor(lastPage) + 1;
    }
    if (total > 0) {
      const from = perPage * currentPage - perPage + 1;
      const to = perPage * currentPage;
      if (total % perPage === 0) {
        return `Showing ${from} to ${to} of ${total} entries`;
      } else {
        if (currentPage === lastPage) {
          return `Showing ${from} to ${(total % perPage) + from - 1} of ${
            total
            } entries`;
        } else {
          return `Showing ${from} to ${to} of ${total} entries`;
        }
      }
    } else {
      return '';
    }
  }

  pagerData(total: any, perPage: any, currentPage: any) {

  }



  isInt(n: any) {
    return n % 1 === 0;
  }

  get getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  get getCurrentMonth() {
    const currentDate = new Date();
    return currentDate.getMonth() + 1;
  }

  get getCurrentDay() {
    const currentDate = new Date();
    return currentDate.getDate();
  }

  showStepAsDone(count: number) {
    const elementArr = [];
    if (
      document.getElementsByClassName('default navigable') &&
      document.getElementsByClassName('default navigable').length
    ) {
      const elem = document.getElementsByClassName('default navigable');
      if (elem && elem.length) {
        for (let i = 0; i < elem.length; i++) {
          if (i < count) {
            $(elem[i]).addClass('done');
          }
          elementArr.push(elem[i]);
        }
      }
    }
    this.notFoundStepAsDone(elementArr, count);
  }

  notFoundStepAsDone(elementArr: any, count: number) {
    if (elementArr && elementArr.length === 0) {
      setTimeout(() => {
        this.showStepAsDone(count);
      }, 200);
    }
  }

  getHiddenStep() {
    const elementArr = [];
    if (
      document.getElementsByClassName('done') &&
      document.getElementsByClassName('done').length
    ) {
      const elem_done = document.getElementsByClassName('done');
      if (elem_done && elem_done.length) {
        for (let i = 0; i < elem_done.length; i++) {
          $(elem_done[i]).css('cursor', 'pointer');
          elementArr.push(elem_done[i]);
        }
      }
    }
    this.hiddenStep(elementArr);
  }

  hiddenStep(elementArr: any) {
    if (elementArr && elementArr.length) {
      for (let i = 0; i < elementArr.length; i++) {
        elementArr[i].addEventListener('click', (e: any) => {
          this.clickOnStep(e.target.textContent);
        });
      }
    } else {
      setTimeout(() => {
        this.getHiddenStep();
      }, 200);
    }
  }

  clickOnStep(stepTitle: any) {
    if (this.stepClick) {
      this.clickedOnStep$.emit(stepTitle);
      this.stepClick = false;
      setTimeout(() => {
        this.stepClick = true;
      }, 1000);
    }
  }

  getStepIndexFromStepTitle(stepTitle: any) {
    let stepNumber = 0;
    switch (stepTitle) {
      case 'Customer Information':
        stepNumber = 0;
        break;
      case 'Vehicle Information':
        stepNumber = 1;
        break;
      case 'Insurance Information':
        stepNumber = 2;
        break;
      case 'Customer Documents Needed':
        stepNumber = 3;
        break;
      case 'Agent Review':
        stepNumber = 4;
        break;
      case 'Agent Documents Upload':
        stepNumber = 5;
        break;
      case 'Calculations':
        stepNumber = 6;
        break;
    }
    return stepNumber;
  }

  removeDate(formGroup: FormGroup, formControl: any) {
    formGroup.get(formControl).setValue(null);
    formGroup.get(formControl).markAsDirty();
  }

  inArray(needle: any, haystack: any) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  }

  getPageList(toalPage: number, currentPage: number) {
    let from = Math.floor(currentPage / 5) * 5;
    let to = (Math.floor(currentPage / 5) + 1) * 5;
    if (from == currentPage && from != 1) {
      from = from - 1;
      to = to - 1;
    }
    if (to > toalPage) {
      to = toalPage;
      from = to - 5;
    }
    from = (from < 1) ? 1 : from;
    // create an array of pages to ng-repeat in the pager control
    let pages: any = [];
    if (currentPage > 4 && from > 2) {
      pages.push({ page: 1, label: '1 .. ' });
    } else if (from == 2) {
      from = 1;
    }
    for (let i = from; i <= to; i++) {
      pages.push({ page: i, label: i });
    }
    if (toalPage > to) {
      pages.push({ page: toalPage, label: ((to + 1) == toalPage) ? toalPage : ' .. ' + toalPage });
    }
    return pages;
  }

  filterUserRoles(roles: any, role: any) {
    let data: any = [];
    let allow: Array<any> = [];
    if (role === 'dowcsuperadmin') {
      allow = ['customer', 'admin', 'superadmin', 'dowcadmin', 'dowcsuperadmin', 'dowcview', 'adminview'];
    } else  if (role === 'dowcview') {
      allow = ['customer', 'admin', 'superadmin', 'dowcview', 'adminview'];
    } else  if (role === 'adminview') {
      allow = ['customer', 'admin', 'superadmin', 'adminview'];
    } else if (role === 'dowcadmin') {
      allow = ['customer', 'admin', 'dowcadmin'];
    } else if (role === 'superadmin') {
      allow = ['customer', 'admin', 'superadmin'];
    } else if (role === 'admin') {
      allow = ['customer', 'admin'];
    } else {
      allow = ['customer'];
    }
    $.each(roles, function (index, val) {
      allow.forEach(function (rol: any) {
        if (val.slug===rol) {
          data.push(val);
        }
      });
    
    });
    return data;
  }

  toggleDivClass(target:any,cls:any){
    if ($("#" + target).hasClass(cls)) {
      $("#" + target).removeClass(cls);
      $(".hidden-xl-up .fa-minus").removeClass("d-none");
      $(".hidden-xl-up .fa-plus").addClass("d-none");
    } else {
      $("#" + target).addClass(cls);
      $(".hidden-xl-up .fa-minus").addClass("d-none");
      $(".hidden-xl-up .fa-plus").removeClass("d-none");
    }

  }

  getProduct(){

  }

  setProduct(){
    
  }

  updateDomainCss(){
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    //console.log(domainconfig);
    if(domainconfig){
      if(domainconfig.primaryColor){
        //$('.btn-primary').css({"background-color":domainconfig.primaryColor});
        document.documentElement.style.setProperty('--primary-color', domainconfig.primaryColor);
        this.changeCssAttribute('nav-item','background-color',domainconfig.primaryColor);
        this.changeCssAttribute('mobile-menu-container','background-color',domainconfig.primaryColor);
        this.changeCssAttribute('btn-primary','background-color',domainconfig.primaryColor);
        this.changeCssAttribute('btn-primary','border-color',domainconfig.primaryColor);
        this.changeCssAttribute('btn-info','background',domainconfig.primaryColor);
        this.changeCssAttribute('btn-info','background-color',domainconfig.primaryColor);
        this.changeCssAttribute('btn-info','border-color',domainconfig.primaryColor);
        this.changeCssAttribute('page-goto','background',domainconfig.primaryColor);
        this.changeCssAttribute('btn-link','color',domainconfig.primaryColor);
        this.changeCssAttribute('goto-btn','background',domainconfig.primaryColor);
        this.changeCssAttribute('logo-container','background',domainconfig.primaryColor);
        //this.changeCssAttribute('help-text','border',domainconfig.primaryColor);
        // this.utilService.changeCssAttribute('nav-item','background-color',domainconfig.primaryColor);
        // $(".nav-item .dropdown-menu").css("background-color",domainconfig.primaryColor);
      }
      if(domainconfig.secondaryColor){
        document.documentElement.style.setProperty('--secondary-color', domainconfig.secondaryColor);         
        this.changeCssAttribute('card-header','background',domainconfig.secondaryColor);       
        this.changeCssAttribute('card-header','background-color',domainconfig.secondaryColor);
        this.changeCssAttribute('title-bg','background-color',domainconfig.secondaryColor);
        this.changeCssAttribute('table-title','background-color',domainconfig.primaryColor);
      }
    } else {
      document.documentElement.style.setProperty('--primary-color', '#009B4C');
      document.documentElement.style.setProperty('--secondary-color', '#A4BB39');
    }
  }

  changeCssAttribute(cls:any,attr:any,val:any){
    $('.' + cls).css(attr, val);
    const elements:any = document.getElementsByClassName(cls);
    //console.log(" ===> ",cls,val,attr);
    for (var i = 0; i < elements.length; i++) {
      //console.log(cls," => ",elements[i].style[attr]," INTO ",val);
        elements[i].style[attr]=val;
    }    
  }

}
