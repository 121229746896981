import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  finalize,
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { CustomerService } from '@app/core/service/customer.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/core/service/util.service';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { LOCAL_VAR } from '@app/core';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  configEditForm: FormGroup;
  isLoading = false;
  isSending = false;
  queryParams: any;
  configs: any;
  HelpMail: string;
  appLogo: string;
  website: string;
  portal: string;
  senderMail: string;
  notificationSenderMail: string;
  supportMail: string;
  notifyClaimMail: string;
  MailFooter: string;
  stoneEagleAPI: string;
  user: any;
  loggedInUserType: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public utilService: UtilService,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    config: NgbTypeaheadConfig
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    localStorage.setItem(LOCAL_VAR.selectedProduct, null);
    //this.createForm();
    this.loadConfig();
  }

  ngOnInit() {
   
    let audit = (this.user.audit ? this.user.audit : 0)
    //console.log(this.loggedInUserType,this.user.audit,audit);
    if (audit !== 1 && this.loggedInUserType !== 'dowcsuperadmin') {
      this.router.navigate(['/products']);
    }
  }

  private createForm() {
    this.configEditForm = this.formBuilder.group({
      website: [(this.configs.website) ? this.configs.website : '', [Validators.required]],
      portal: [(this.configs.portal) ? this.configs.portal : '', [Validators.required]],
      appLogo: [(this.configs.appLogo) ? this.configs.appLogo : '', [Validators.required]],
      senderMail: [(this.configs.senderMail) ? this.configs.senderMail : '', [Validators.required]],
      notificationSenderMail: [(this.configs.notificationSenderMail) ? this.configs.notificationSenderMail : '', [Validators.required]],
      HelpMail: [(this.configs.HelpMail) ? this.configs.HelpMail : '', [Validators.required]],
      supportMail: [(this.configs.supportMail) ? this.configs.supportMail : '', [Validators.required]],
      notifyClaimMail: [(this.configs.notifyClaimMail) ? this.configs.notifyClaimMail : '', [Validators.required]],
      dealersNotifyMail: [(this.configs.dealersNotifyMail) ? this.configs.dealersNotifyMail : ''],
      defaultMail: [(this.configs.defaultMail) ? this.configs.defaultMail : ''],
      notifyToDefault: [(this.configs.notifyToDefault && this.configs.notifyToDefault === "enable") ? true : false],
      MAIL_FLAG: [(this.configs.MAIL_FLAG && this.configs.MAIL_FLAG === "enable") ? true : false],
      MailFooter: [(this.configs.MailFooter) ? this.configs.MailFooter : ''],
      stoneEagleAPI: [(this.configs.stoneEagleAPI) ? this.configs.stoneEagleAPI : '']
    });
  }

  loadConfig() {
    this.isLoading = true;
    this.customerService
      .getConfig()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.configs = response.records;
        this.isLoading = false;
        if (this.configs) {
          this.createForm();
          this.utilService.updateDomainCss();
        }
      });
  }

  updateConfig() {
    this.isLoading = true;
    const payload = this.configEditForm.value;
    payload.MAIL_FLAG = (payload.MAIL_FLAG) ? 'enable' : 'disable';
    payload.notifyToDefault = (payload.notifyToDefault) ? 'enable' : 'disable';
    const data: any = [];
    $.each(payload, function (key, value) {
      let val = { name: key, value: value }
      data.push(val);
    })


    this.customerService
      .updateConfig(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        this.loadConfig();
        this.toastrService.info('Config updated Successfully');
      });
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

}
